import React, { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Box, FormControl, Grid } from "@mui/material";
import InputComponent from "../../../../componets/form/inputComponent";
import IconButtonComponent from "../../../../componets/form/iconButtonComponent";
import ButtonComponent from "../../../../componets/form/buttonComponent";
import CoperateUser from "../../../../services/coperateAdmin/coperateUser";
import { useFormik } from "formik";
import DatePickerComponent from "../../../../componets/form/datePickerComponent/index";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import AlertComponent from "../../../../componets/alert";
import moment from "moment/moment";



// styles
import "./styles/style.scss";
import DropDownComponent from "../../../../componets/form/dropDownComponent";
import Validation from "../../../../utils/Validation";
import TokenService from "../../../../services/axios/token";
import PhoneNumberComponent from "../../../../componets/form/phoneNumberComponent";
import { formatPhoneNumberIntl } from "react-phone-number-input";





const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const UpdateEmployee = ({ open, onClose, userId, getAllEmployees }) => {
    const responseSuccess = 1;
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [employeeInfo, setEmployeeInfo] = useState({});
    const [currentUser, setCurrentUser] = useState("");
    const [employeeData, setEmployeeData] = React.useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNo: "",
        address: "",
        gender: "",
        dob: ""
    });
    const [errors, setErrors] = React.useState({});
    const [touchedFields, setTouchedFields] = React.useState({});
    const [checkErrors, setCheckErrors] = React.useState(true);



    useEffect(() => {
        setCurrentUser(TokenService.getUserId);
        getEmployee(userId);
    }, [userId, getAllEmployees]);


    const getEmployee = (userId) => {
        CoperateUser.getEmployee(userId).then((response) => {
            if (response?.data?.status_code == responseSuccess) {
                setEmployeeInfo(response?.data?.data?.details);
                setEmployeeData({
                    firstName: response?.data?.data?.details?.first_name,
                    lastName:response?.data?.data?.details?.last_name,
                    email:response?.data?.data?.details?.email,
                    contactNumber:response?.data?.data?.details?.phone_number,
                    address:response?.data?.data?.details?.address,
                    gender:response?.data?.data?.details?.gender,
                    dob:response?.data?.data?.details?.dob !== null ? new Date(moment(response?.data?.data?.details?.dob)) : ""
                })
            } else {
                setErrorMessage(response?.data?.message);
                setError(true);
            }
        }).catch((error) => { console.log(error) });
    }





    const updateEmployee = (userId, firstName, lastName, email, phoneNo, address, gender, dob) => {
        CoperateUser.editEmployee(userId, firstName, lastName, email, phoneNo, address, gender, moment(dob).format('Y-MM-D')).then((response) => {
            if (response?.data?.status_code == responseSuccess) {
                getAllEmployees(currentUser);
                setSuccessMessage(response?.data?.message);
                getEmployee(userId);
                setSuccess(true);


            } else {
                setErrorMessage(response?.data?.message);
                setError(true);
            }
        }).catch((error) => { console.log(error) });
    }



    const handleFieldBlur = (fieldName) => {
        setTouchedFields((prevTouchedFields) => ({
            ...prevTouchedFields,
            [fieldName]: true,
        }));
    };


    const handleChange = async (field, value) => {
        setEmployeeData((employeeData) => ({
            ...employeeData,
            [field]: value
        }));

        setErrors((employeeData) => ({
            ...employeeData,
            [field]: '',
        }));

        try {
            await Validation.Employee.validate(employeeData, { abortEarly: false });
            setCheckErrors(false);

        } catch (validationErrors) {
            const errorsObj = {};
            validationErrors.inner.forEach((error) => {
                errorsObj[error.path] = error.message;
            });
            setErrors(errorsObj);
        }


    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await Validation.Employee.validate(employeeData, { abortEarly: false });

            updateEmployee(
                userId,
                employeeData?.firstName,
                employeeData?.lastName,
                employeeData?.email,
                employeeData?.contactNumber,
                employeeData?.address,
                employeeData?.gender,
                employeeData?.dob);

        } catch (validationErrors) {
            const errorsObj = {};
            validationErrors.inner.forEach((error) => {
                errorsObj[error.path] = error.message;
            });
            setErrors(errorsObj);
        }
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogActions>
                    <IconButtonComponent type="close" buttonClick={onClose} />
                </DialogActions>
                <DialogTitle>Update Employee Information</DialogTitle>

                <DialogContent>
                    <Box>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Box className="form-content">
                                        <Box className="form-item">
                                            <InputComponent
                                                label="First Name"
                                                type="text"
                                                placeholder="First Name"
                                                name="firstName"
                                                value={employeeData.firstName}
                                                onChange={(e) => handleChange('firstName', e.target.value)}
                                                onBlur={() => handleFieldBlur('firstName')}
                                                error={touchedFields.firstName && errors.firstName} />
                                        </Box>
                                        <Box className="form-item">
                                            <InputComponent
                                                label="Email"
                                                type="text"
                                                placeholder="Email"
                                                name="email"
                                                value={employeeData.email}
                                                onChange={(e) => handleChange('email', e.target.value)}
                                                onBlur={() => handleFieldBlur('email')}
                                                error={touchedFields.email && errors.email}
                                            />
                                        </Box>
                                        <Box className="form-item">
                                            <PhoneNumberComponent
                                                placeholder="Contact Number"
                                                defaultCountry="GB"
                                                name="contactNumber"
                                                value={employeeData.contactNumber}
                                                onChange={(e) => handleChange('contactNumber', formatPhoneNumberIntl(e))}
                                                onBlur={() => handleFieldBlur('contactNumber')}
                                                error={touchedFields.contactNumber && errors.contactNumber}
                                            />
                                        </Box>

                                        
                                        <Box className="form-item">
                                            <DatePickerComponent
                                                label="Dob"
                                                id="dob"
                                                name="dob"
                                                dateFormat="dd-MM-yyyy"
                                                selected={employeeData?.dob}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                onBlur={() => handleFieldBlur('dob')}
                                                onChange={(e) => handleChange('dob', e)} />
                                        </Box>
                                       


                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box className="form-content">
                                        <Box className="form-item">
                                            <InputComponent label="Last Name"
                                                type="text"
                                                placeholder="Last Name"
                                                name="lastName"
                                                value={employeeData.lastName}
                                                onChange={(e) => handleChange('lastName', e.target.value)}
                                                onBlur={() => handleFieldBlur('lastName')}
                                                error={touchedFields.lastName && errors.lastName} />
                                        </Box>
                                        <Box className="form-item">
                                            <DropDownComponent
                                                labelAbove="Gender"
                                                value={employeeData.gender}
                                                onChange={(e) => handleChange('gender', e.target.value)}
                                                onBlur={() => handleFieldBlur('gender')}
                                                error={touchedFields.gender && errors.gender}
                                                fullWidth
                                                size="small"
                                            />
                                        </Box>
                                       

                                        <Box className="form-item">
                                            <InputComponent
                                                label="Address"
                                                placeholder="Address"
                                                name="address"
                                                value={employeeData.address}
                                                onChange={(e) => handleChange('address', e.target.value)}
                                                onBlur={() => handleFieldBlur('address')}
                                                error={touchedFields.address && errors.address}
                                                textboxarea={"textArea"}
                                                rows={8}
                                            />
                                        </Box>


                                        <Box className="button-group">
                                            <Box className="button-wrap">
                                                <ButtonComponent submit="submit" buttoncolor="#657ee4" buttontext="Save" buttonwidth={100} onClick={handleSubmit} />
                                            </Box>
                                            <Box className="button-wrap">
                                                <ButtonComponent buttontext="Cancel" buttonwidth={100} buttoncolor="#616D89" onClick={onClose} />
                                            </Box>
                                        </Box>

                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>

                </DialogContent>

            </Dialog>

            <AlertComponent title={"Success"} description={successMessage} buttontext={"OK"} type={"success"} open={success} onClose={() => { setSuccess(false); onClose(); }} />
            <AlertComponent title={"Error"} description={errorMessage} buttontext={"OK"} type={"error"} open={error} onClose={() => setError(false)} />

        </>

    );
}
export default UpdateEmployee;