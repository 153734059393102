import React from "react";
import { Box } from "@mui/material";



// style
import './style/style.scss';
// responsive
// import './style/responsive.scss';
// assets
import Images from "../../assets/images";
import ResetPasswordForm from "./components/resetForm";





const ResetPassword = () => {
    return (
        <Box className="content-wrapper">
            <Box className="sub-container">
                <Box className="logo-wrap">
                    <img src={Images.logo} alt="logo" />
                </Box>

                <Box className="form-wrap">
                    <Box className="form-inner-wrap">
                        <ResetPasswordForm />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default ResetPassword;