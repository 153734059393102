import { Outlet, Navigate } from "react-router-dom";
import TokenService from "../services/axios/token";

const PrivateRoutes = () => {
    let auth = TokenService.getToken();
    return (<>
    {auth ? <Outlet/>: <Navigate to="/"/>}
    </>);
}

export default PrivateRoutes;