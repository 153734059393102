import baseApi from "../axios/axios";
import { paths } from "../paths";

const signInApi = async (username, password) => {
    return await baseApi.Commoninstance.post(paths.signIn, { email: username, password: password });
}

const forgetPasswordApi = async (email) => {
    return await baseApi.Commoninstance.post(paths.forgotPassword, { email: email });
}

const resetPasswordApi = async (token, email, newPassword, confirmPassword) => {
    return await baseApi.Commoninstance.post(paths.resetPassword, { token: token, email: email, password: newPassword, password_confirmation: confirmPassword });
}

const signOutApi = async () => {
    return await baseApi.instance.post(paths.logout);
}


const Authapi = {
    signInApi,
    forgetPasswordApi,
    resetPasswordApi,
    signOutApi
};

export default Authapi;