// Auth Section
const signIn = "/login";
const forgotPassword = "/password/resetMail";
const resetPassword = "/password/reset";
const logout = "/logout";

//  Super Admin
const allPrivateUsersDetails = "/getAllPrivateUsers";
const privateUserDetails = "/getSpecificPrivateUser";
const deletePrivateUser = "/deletePrivateUser";
const changeUserAccountStatus = "/changeUserStatus";


// Change Password
const changePassword = "/changePassword";

// Co-Operate Clients
const allCoperateClients = "/getAllCorporateClients";
const coperateClient = "/getSpecificCorporateClient";
const createCoparateClient = "/createCorporateClient";
const deleteCoparateClient = "/deleteCorporateClientAccount";
const updateCoparateClient = "/editCorporateClient";
const changeCoperateClientStatus = "/changeCorporateClientStatus";

// Dashboard
const dashboardDetails = "/getSuperAdminDashboardData";

// employee
const getAllEmployees = "/getAllCorporateEmployees";
const getEmployee = "/getSpecificCorporateEmployee";
const createEmployee = "/createCorporateEmployee";
const editEmployee = "/editCorporateEmployee";
const importBulkEmployee = "/importBulkEmployees";
const changeEmployeeStatus = "/changeEmployeeStatus";
const deleteEmployee = "/deleteCorporateEmployee";
const getCompanyInfo = "/getCorporateCompany";
const editCompanyInfo = "/updateCorporateCompany";

export const  paths = {
    signIn,
    forgotPassword,
    resetPassword,
    logout,
    allPrivateUsersDetails,
    privateUserDetails,
    deletePrivateUser,
    changeUserAccountStatus,
    changePassword,
    allCoperateClients,
    coperateClient,
    createCoparateClient,
    deleteCoparateClient,
    updateCoparateClient,
    changeCoperateClientStatus,
    dashboardDetails,
    deleteEmployee,
    changeEmployeeStatus,
    importBulkEmployee,
    editEmployee,
    createEmployee,
    getEmployee,
    getAllEmployees,
    getCompanyInfo,
    editCompanyInfo
}






