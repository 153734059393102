import * as React from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Box, Grid, Typography } from "@mui/material";
import InputComponent from "../../../../componets/form/inputComponent";
import CommonMethods from "../../../../utils/CommonMethods";

// styles
import "./styles/style.scss";
import IconButtonComponent from "../../../../componets/form/iconButtonComponent";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ViewUserDialog = (props) => {
  const { open, onClose, userInfo} = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogActions>
        <IconButtonComponent type="close" buttonClick={onClose}/>

      </DialogActions>
      <DialogTitle> <h4>View User</h4></DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item>
            <Box className="form-content">
              <Box className="form-item">
                <InputComponent label="First Name" value={userInfo?.first_name} readOnly/>
              </Box>
              <Box className="form-item">
                <InputComponent label="Mobile Number" value={userInfo?.mobile_number} readOnly/>
              </Box>

              <Box className="form-item">
                <InputComponent label="Dob"  value={CommonMethods.convertDate(userInfo?.dob)} readOnly/>
              </Box>

              < Box className="form-item">
                <InputComponent label="Email"  value={userInfo?.email} readOnly/>
              </Box>

              <Box className="form-item">
                <InputComponent label="Gender" value={userInfo?.gender} readOnly/>
              </Box>
            </Box>
          </Grid>
          <Grid item>
            <Box className="form-content">
              <Box className="form-item">
                <InputComponent  label="Last Name" value={userInfo?.last_name} readOnly/>
              </Box>
              <Box className="form-item">
                <InputComponent label="Alt Number"  value={userInfo?.alt_mobile_number} readOnly/>
              </Box>

              <Box className="form-item">
                <InputComponent  label="Address" value={userInfo?.address}  textboxarea="textArea"  readOnly/>
              </Box>


              <Box className="form-item">
                <p><i>Current Subscription Period</i></p>
                {/* <Typography variant="body1" sx={{fontWeight:"bold"}}>Current Subscription Period</Typography> */}
                <Box className="current-subscription-wrap">
                <Box p={0.5}>
                  <InputComponent label="From Date" value={userInfo?.subscription_start !== null ? CommonMethods.stringDateToDate(userInfo?.subscription_start):""} readOnly />
                </Box>

                <Box  p={0.5}>
                  <InputComponent label="To Date" value={userInfo?.subscription_end !== null ? CommonMethods.stringDateToDate(userInfo?.subscription_end): ""} readOnly/>
                </Box>
                </Box>
                
              </Box>


            </Box>
          </Grid>
        </Grid>
      </DialogContent>

    </Dialog>
  );
}
export default ViewUserDialog;