import React from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { Typography } from "@mui/material";


// styles
import "./styles/style.scss";


const FooterComponent = () => {
    return (<AppBar position="static" className="footer-position">
    <Container maxWidth="xl">
        <Toolbar disableGutters sx={{justifyContent:"center"}}>
        <Box>
                <Typography variant="caption" sx={{color:"#050505"}}>
                Copyright © 2023 Gogodoc
                </Typography>
            </Box>
        </Toolbar>
    </Container>
</AppBar>)
}
export default FooterComponent;