import { Box } from "@mui/material";
import React, { useState,useEffect } from "react";
// import AlertComponent from "../../componets/alert/index";
import SignInComponent from "./components/signInComponent";
import ForgotPasswordComponent from "./components/forgotPasswordComponent";

// style
import './style/style.scss';
// responsive
import './style/responsive.scss';
// assets
import Images from "../../assets/images";




const Login = () => {
    const [forgotPassword, setForgotPassword] = useState(1);

    const clickForgotPassword = (state) => {
        setForgotPassword(state)
    };


    return (
        <Box className="content-wrapper">
            <Box className="sub-container">
                <Box className="logo-wrap">
                    <img src={Images.logo} alt="logo" />
                </Box>
                <Box className="form-wrap">
                    <Box className="form-inner-wrap">{forgotPassword === 2  ? (<ForgotPasswordComponent setForgotPasswordState={clickForgotPassword} />) : (<SignInComponent setForgotPasswordState={clickForgotPassword} />)}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Login;