import React,{ useMemo } from "react";
import './style/style.scss';
import { Box, Button } from "@mui/material";
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';

const ButtonComponent = (props) => {
  const { buttontext, buttonwidth, buttoncolor } = props;
  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    // backgroundColor: "#657ee4",
    backgroundColor: buttoncolor,
    textTransform: "capitalize",
    borderRadius: 5,
    fontFamily: '"Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    width: buttonwidth,
    '&:hover': {
      backgroundColor: buttoncolor,
      boxShadow: "0 2px 7px rgba(0, 0, 0, 0.28)",
      cursor: "pointer",
    },
  }));
  return (
    <>
      <Button variant="contained" style={{width:buttonwidth, backgroundColor:buttoncolor}} {...props}><Box >{buttontext}</Box></Button>
    </>
  )
}

export default ButtonComponent;