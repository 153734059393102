import React from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import './style/style.scss';
import { FormControl } from "@mui/material";


const DropDownComponent = (props) => {
    const { placeHolder, labelAbove, name, value, onChange, onBlur, error} = props;
    return (
        <>
            <p sx={{ fontWeight: "bold" }}>
                {labelAbove}
            </p>

            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{placeHolder}</InputLabel>
                <Select
                    id="demo-simple-select-label"
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={error}
                    size="small"
                >
                    <MenuItem value={"male"}>Male</MenuItem>
                    <MenuItem value={"female"}>Female</MenuItem>
                </Select>
            </FormControl>
        </>
    )
}

export default DropDownComponent;