import React from "react";
import { Box, Typography } from "@mui/material";


// styles
import './styles/boxStyle.scss';

const BoxComponent = ({ title, value, icon }) => {
    return (
        <Box className="box-wrap" title={value}>
            <Box className="align-text">
                <Box className="top-section">
                    <Box className="value-text-wrap">
                        <Typography variant="h3" sx={{color:"#fff"}} title={value}>{value}</Typography>
                    </Box>
                    <Box className="img-wrap">
                        <img src={icon} alt="icon" />
                    </Box>
                </Box>  
                <Typography variant="caption" className="text-style">{title}</Typography>
            </Box>
        </Box>
    );
}

export default BoxComponent