import React, { useState } from "react";
import { Box} from "@mui/material";
import InputComponent from "../../componets/form/inputComponent";
import ButtonComponent from "../../componets/form/buttonComponent";
import PageTitleComponent from "../../componets/pageTitle";
import AlertComponent from "../../componets/alert";
import SuperAdmin from "../../services/superAdmin/superAdmin";
import { useFormik } from "formik";
import Validation from "../../utils/Validation";

// styles
import "./styles/style.scss";
import "./styles/responsive.scss";
// resource
import images from "../../assets/images";
// import SubmitButtonComponent from "../../componets/form/submitButtonCompoent";





const ChangePassword = () => {
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [updateError, setUpdateError] = useState(false);
    const [errormessage, setErrormessage] = useState("");
    const responseSuccess = 1;

    const onSubmit = async(values, actions) => {
        console.log("submitted", actions);
        changePassword(values.currentPassword, values.newPassword, values.confirmPassword);
        actions.resetForm();
    }


    const changePassword = async (currentPassword, newPassword, confirmPassword) => {
        SuperAdmin.changePasswordApi(currentPassword, newPassword, confirmPassword).then((response) => {
            console.log(response);
            if (response.data.status_code === responseSuccess) {
                setUpdateSuccess(true);
            } else {
               setErrormessage(response.data.message);
               setUpdateError(true);
            }
        }).catch((error) => { console.error(error) });
    }


    const { values, handleBlur, handleChange, handleSubmit, errors, touched, handleReset } = useFormik({
        initialValues: {
            currentPassword:"",
            newPassword: "",
            confirmPassword: ""
        },
        onSubmit,
        validationSchema: Validation.ChangePassword
    });
    
    return (
        <Box className="page-main-continer">
            <Box className="content-container">
                <Box className="content-wrap">
                    <Box className="pc-top-section">
                        <PageTitleComponent title="Password Settings"/>
                    </Box>

                    <Box className="form-section">
                    <Box className="change-pw-logo">
                        <img src={images.changePassword} alt="Change Password" />
                    </Box>
                    <Box>
                    <form onSubmit={handleSubmit}>
                            <Box className="form-content-wrap">
                                <Box>
                                <InputComponent
                                label="Current Password"
                                type="password"
                                placeholder="Current Password"
                                name="currentPassword"
                                value={values.currentPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.currentPassword && errors.currentPassword}
                            />
                                </Box>
                                <Box>
                                <InputComponent
                                 label="New Password"
                                type="password"
                                placeholder="New Password"
                                name="newPassword"
                                value={values.newPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.newPassword && errors.newPassword}
                            />
                                </Box>
                                <Box>
                                <InputComponent
                                 label="Confirm Password"
                                type="password"
                                placeholder="Confirm Password"
                                name="confirmPassword"
                                value={values.confirmPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.confirmPassword && errors.confirmPassword}
                            />
                                </Box>

                                <Box className="button-group-wrap">
                                    <Box className="button-group-item">
                                        <ButtonComponent type="submit" onClick={handleSubmit} buttontext="Save" buttonwidth={110} buttoncolor="#657ee4"/>
                                    </Box>
                                    <Box className="button-group-item">
                                        <ButtonComponent buttontext="Cancel" buttonwidth={110}  onClick={handleReset} buttoncolor="#616D89" />
                                    </Box>
                                </Box>
                            </Box>
                        </form>
                    </Box>
                        
                    </Box>

                </Box>
            </Box>

            <AlertComponent title="Success" description="You have successfully updated your password." buttontext="OK"  type="success" open={updateSuccess} onClose={()=>{setUpdateSuccess(false)}} />
            <AlertComponent title="Error" description={errormessage} buttontext="Ok"  type="error" open={updateError} onClose={()=>{setUpdateError(false)}} />
        </Box>);
}

export default ChangePassword;    