import React, { useEffect, useState } from "react";
import SearchComponent from "../../componets/search";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, tableCellClasses } from "@mui/material";
import PageTitleComponent from "../../componets/pageTitle";
import AlertComponent from "../../componets/alert";
import ViewUserDialog from "./component/viewUser";
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import _ from "lodash";
import SuperAdmin from "../../services/superAdmin/superAdmin";
import SwitchComponent from "../../componets/form/switchComponent";
import IconButtonComponent from "../../componets/form/iconButtonComponent";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


// styles
import "./styles/style.scss";
import CommonMethods from "../../utils/CommonMethods";



const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const PrivateUsers = () => {
    const [viewUser, setViewUser] = useState(false);
    const [deleteRecord, setDeleteRecord] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false); 
    const [errorMsg, setErrorMsg] = useState(""); 
    const [allPrivateUsers, setAllPrivateUsers] = useState([]);
    const [filteredPrivateUsers, setFilteredPrivateUsers] = useState([]);
    const [userid, setUserid] = useState(0);
    const responseSuccess = 1;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [deleteUserSuccess, setDeleteUserSuccess] = useState(false);
    const [userDetails, setUserDetails] = useState({});
 



    useEffect(() => {
        getAllPrivateUsers();
    }, []);

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    const getAllPrivateUsers = async () => {
        SuperAdmin.getAllPrivateUsersApi().then((response) => {
            if (response.data.status_code === responseSuccess) {
                const list = _.get(response, 'data.data', []);
                setAllPrivateUsers(list);
                setFilteredPrivateUsers(list);
            } else {
                   setErrorMsg(response.data.message);
                   setErrorAlert(true);
            }
        }).catch((error) => { console.error(error) });
    }

    const updatePrivateUserStatus = async (userId, status) => {
        SuperAdmin.changePrivateUserStatusApi(userId, status).then((response) => {
            if (response.data.status_code === responseSuccess) {
                getAllPrivateUsers();
                toast.success('Status updated successfully', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            } else {
                toast.error(response.data.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        }).catch((error) => { console.error(error) });
    }

    const deletePrivateUser = async (userId) => {
        SuperAdmin.deletePrivateUserApi(userId).then((response) => {
            if (response?.data?.status_code === responseSuccess) {
                getAllPrivateUsers();
                setDeleteUserSuccess(true);
            } else {
                setErrorMsg(response?.data?.message);
                setErrorAlert(true);
            }
        }).catch((error) => { console.error(error) });
    }

    const getSpecificPrivateUser = async (userId) => {
        SuperAdmin.getPrivateUserApi(userId).then((response) => {
            if (response.data.status_code === responseSuccess) {
                setUserDetails(response?.data?.data);
                setViewUser(true);
            } else {
                setErrorMsg(response?.data?.message);
                setErrorAlert(true);
            }
        }).catch((error) => { console.error(error) });
    }

    const handleSwitch = (_, userId) => {
        const list = allPrivateUsers.map((data) => {
            if (data?.user_id == userId) {
                const currentState = data?.status;
                const status = currentState == 1 ? 0 : 1;
                updatePrivateUserStatus(userId, status);
                return { ...data, status }
            }
            return data;
        });
        setAllPrivateUsers(list);
    }

    const closeUserinfo = () => {
        setViewUser(false);
    }

    const searchInput = (value) => {
        const filteredData = allPrivateUsers.filter(record =>
            record.first_name.toLowerCase().includes(value.toLowerCase()) || record.last_name.toLowerCase().includes(value.toLowerCase()) || record.mobile_number.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredPrivateUsers(filteredData);
    }
    const deletebuttonClick = (value) => {
        setDeleteRecord(true);
        setUserid(value);
    }

    const deleteUser = () => {
        deletePrivateUser(userid);
        setDeleteRecord(false);
    }
    
    const viewPrivateUser = (userId) => {
        getSpecificPrivateUser(userId);

    }


    const privateUserTableColumn = [
        { name: "S No" },
        { name: "First Name" },
        { name: "Last Name" },
        { name: "Phone Number" },
        { name: "Current Subscription Period" },
        { name: "Status" },
        { name: "Action" },
    ];


    return (
        <Box className="content-container">
            <Box className="content-wrap">
                <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
                <Box className="title-wrap">
                    <PageTitleComponent title="Private Users" />
                </Box>

                <Box className="search-section">
                    <Box className="search-wrap">
                        <SearchComponent getSearchValue={searchInput} />
                    </Box>
                </Box>

                <Box className="table-wrap">
                    <>
                        <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                            <TableContainer component={Paper} sx={{ maxHeight: 850 }}>
                                <Table stickyHeader aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            {privateUserTableColumn.map((column, index) => (
                                                <StyledTableCell align="center" key={index}>{column.name}</StyledTableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredPrivateUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, index) => (
                                            <StyledTableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell align="center" component="th" scope="row">
                                                    {data?.user_id}
                                                </TableCell>
                                                <TableCell align="center">{data?.first_name}</TableCell>
                                                <TableCell align="center">{data?.last_name}</TableCell>
                                                <TableCell align="center">{data?.mobile_number}</TableCell>
                                                <TableCell align="center"><Box sx={{ display: "flex", justifyContent:"center",flexDirection: "row", gap:1}}>
                                                    <Box>{data?.subscription_start !== null ? CommonMethods.convertDate(data?.subscription_start): ""}</Box>
                                                    <p> - </p>
                                                    <Box>{data?.subscription_start !== null ? CommonMethods.convertDate(data?.subscription_end) : ""}</Box>
                                                </Box></TableCell>
                                                <TableCell align="center"><SwitchComponent checked={data?.status == 1 ? true : false} key={data?.user_id}
                                                    onChange={() => {
                                                        handleSwitch(index, data?.user_id);
                                                    }} inputProps={{ 'aria-label': 'controlled' }} /></TableCell>
                                                <TableCell align="center">
                                                    <Box sx={{ display: "flex", gap: 0.5 }}>
                                                        <Box><IconButtonComponent type="view" buttonClick={() => viewPrivateUser(data.user_id)} /></Box>
                                                        <Box><IconButtonComponent type="delete" buttonClick={() => deletebuttonClick(data.user_id)} /></Box>
                                                    </Box></TableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={allPrivateUsers.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Box>
                    </>
                </Box>
            </Box>

            <ViewUserDialog open={viewUser} onClose={closeUserinfo} userInfo={userDetails} />
            <AlertComponent title="Success" description="User deleted successfully"  buttontext="OK" type="success" open={deleteUserSuccess} onClose={() => { setDeleteUserSuccess(false) }} />
            <AlertComponent title="Error" description={errorMsg}  buttontext="OK" type="error" open={errorAlert} onClose={() => setErrorAlert(false) } />
            <AlertComponent description="Are you sure, You want to Delete?" handleButtonClick={() => deleteUser()} buttontext="Yes" buttontext2="No" type="error" open={deleteRecord} onClose={() => { setDeleteRecord(false) }} />

        </Box>
    );
}

export default PrivateUsers;    