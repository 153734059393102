import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
// import ButtonComponent from "../../../componets/form/buttonComponent";
import InputComponent from "../../../componets/form/inputComponent";
import { useFormik } from "formik";
import Authapi from "../../../services/login/login";
import Validation from "../../../utils/Validation";
import { useNavigate } from "react-router-dom";
import AlertComponent from "../../../componets/alert";
import ButtonComponent from "../../../componets/form/buttonComponent";

const ResetPasswordForm = () => {
    const [authValues, setAuthValues] = useState({});
    const [alert, setAlert] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const navigate = useNavigate();
    const authenticated = 1;

    useEffect(() => {
        setAuthValues(getTokenNemail(window.location.pathname));
    }, []);

    const onSubmit = (values) => {
        resetPassword(authValues.token, authValues.email, values.password, values.confirmPassword);
    }
    const getTokenNemail = (url) => {
        let parts = url.split("/");
        let token = parts[3];
        let email = parts[4];

        return {
            token: token,
            email: email
        }
    }

    const alertOkButton = () => {
        setAlert(false);
        navigate("/");
    }

    const resetPassword = async (token, email, newPassword, confirmPassword) => {
        Authapi.resetPasswordApi(token, email, newPassword, confirmPassword).then((response) => {
            console.log(response);
            if (response.data.status_code === authenticated) {
                setAlert(true);
                setTimeout(() => {
                    setAlert(false);
                    navigate("/");
                }, 5000);

            } else {
                setErrorMsg(response.data.message);
            }
        }).catch((error) => { console.error(error) });
    }


    const { values, handleBlur, handleChange, handleSubmit, errors, touched } = useFormik({
        initialValues: {
            password: "",
            confirmPassword: ""
        },
        onSubmit,
        validationSchema: Validation.RestPasswordValidation
    });

    return (
        <Box>
            <Box className="text-wrap">
                <Box>
                    <h4>Reset Password</h4>
                </Box>

                <Box className="text-description">
                    <p>Please enter new password.</p>
                </Box>
            </Box>
            <Box className="form-style">
                <form onSubmit={handleSubmit}>
                    <Box className="form-input-wrap">
                        <Box>
                            <InputComponent
                                type="password"
                                placeholder="Password"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.password && errors.password}
                            />
                        </Box>
                        <Box >
                            <InputComponent
                                type="password"
                                placeholder="Confirm Password"
                                name="confirmPassword"
                                value={values.confirmPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.confirmPassword && errors.confirmPassword}
                            />
                        </Box>
                        {errorMsg !== "" ? <Box sx={{ width: 280 }}>
                            <p style={{ color: "red" }}>{errorMsg}</p>
                        </Box> : null}

                        <Box className="btn-wrap">
                            <ButtonComponent type="submit" onClick={handleSubmit} buttontext="Save Password" buttoncolor="#657ee4"/>
                        </Box>
                    </Box>

                </form>
            </Box>
            <AlertComponent title="Suceess" description="Your password has been reset successfully." buttontext="OK" type="success" open={alert} onClose={()=>setAlert(false)} handleButtonClick={() => alertOkButton()} />
        </Box>
    )
}

export default ResetPasswordForm;