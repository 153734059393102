import React, { useState } from "react";
import { Box, Input, InputAdornment } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

// styles
import "./styles/style.scss";

const SearchComponent = ({ getSearchValue }) => {
    const [searchValue, setSearchValue] = useState("");

    const handleChange = (e) => {
        setSearchValue(e.target.value);
        getSearchValue(e.target.value);
    };
    return (

        <Box className="input-icon-wrap">
            <Input
                size="small"
                className="search-input"
                placeholder="Search"

                value={searchValue}
                onChange={(e) => handleChange(e)}
                startAdornment={
                    <InputAdornment position="start">
                        <SearchIcon fontSize="medium" />
                    </InputAdornment>
                }
            /></Box>

    )
}

export default SearchComponent;