import React from "react";
import {Box } from "@mui/material";
import './style/style.scss';


const InputComponent = (props) => {
   const  { error,  textboxarea, label} = props;
    return (
        <>
            <p  sx={{fontWeight:"bold"}}>
                {label}
            </p>
            {textboxarea === "textArea" ? <textarea className="form-control"  style={{height:"100px"}} {...props} /> : <input className="form-control" {...props}/>}


            {error !== undefined ? (<Box ><p className="error-text">{props.error}</p></Box>) : null}
        </>
    )
}

export default InputComponent