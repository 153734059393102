import React from "react";
import { Box, Button } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import "./style/style.scss";
import Images from "../../assets/images";

const AlertComponent = ({ title, description, buttontext, buttontext2, type, open, onClose = () => { }, handleButtonClick, data }) => {
    return (<>
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >

            {type === "success" ? (<Box>
                <Box className="check-circle-icon">
                    <CheckCircleIcon sx={{ color: "#1aad07", fontSize: 65 }} />
                </Box>
            </Box>) : (null)}

            {type === "error" ? (<Box>
                <Box className="check-circle-icon">
                    <InfoIcon fontSize="50" sx={{ color: "#e81313", fontSize: 65 }} />
                </Box>
            </Box>) : (null)}

            {type === "info" ? (<Box>
                <Box className="check-circle-icon">
                    <InfoIcon fontSize="50" sx={{ color: "#f29d35", fontSize: 65 }} />
                </Box>
            </Box>) : (null)}

            {type === "confirm-email" ? (<Box>
                <Box className="check-circle-icon">
                    <img src={Images.confirmEamil} alt="confirm-email" />
                </Box>
            </Box>) : (null)}

            {type === "error-bulk-user" ? (<Box>
                <Box className="check-circle-icon">
                    <InfoIcon fontSize="50" sx={{ color: "#e81313", fontSize: 65 }} />
                </Box>
            </Box>) : (null)}

            <DialogTitle className="title-text">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText className="description">
                    {description}
                </DialogContentText>
                {data ? <DialogContent sx={{ display: "flex", justifyContent: "space-around", mr: 2.5, height: 120 }}>
                    <ol>
                        {data?.map((item, index) =>
                            (<li key={index} style={{ color: "black", fontSize: 15, fontFamily: "Montserrat, sans-serif" }} >{item?.email}</li>)
                        )}
                    </ol>
                </DialogContent> : null}



            </DialogContent>


            {buttontext2 === undefined ? (
                <Box className="single-button">
                    <Button variant="contained" onClick={onClose}>{buttontext}</Button>
                </Box>
            ) : <Box className="double-button">
                <Box>
                    <Button variant="contained" onClick={handleButtonClick}>{buttontext}</Button>
                </Box>
                <Box>
                    <Button variant="contained" onClick={onClose}>{buttontext2}</Button>
                </Box>
            </Box>}
        </Dialog>
    </>

    );
}

export default AlertComponent;