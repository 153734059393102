import React, { useEffect, useState } from "react";
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, tableCellClasses } from "@mui/material";
import PageTitleComponent from "../../componets/pageTitle";
import SearchComponent from "../../componets/search";
import Paper from '@mui/material/Paper';
import UploadBulkUsers from "./component/uploadBulk";
import AlertComponent from "../../componets/alert";
import TextIconButtonComponent from "../../componets/form/textIconButtonComponent";
import AddEmployeeForm from "./component/addEmployee";
import CoperateUser from "../../services/coperateAdmin/coperateUser";
import _ from "lodash";
import TokenService from "../../services/axios/token";
import SwitchComponent from "../../componets/form/switchComponent";
import IconButtonComponent from "../../componets/form/iconButtonComponent";
import UpdateEmployee from "./component/updateEmployee";
import { ToastContainer, toast } from "react-toastify";




// styles
import "./styles/style.scss";
import { styled } from '@mui/material/styles';
import 'react-toastify/dist/ReactToastify.css';




const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));



const Employees = () => {

    const [addEmployee, setAddEmployee] = useState(false);
    const [editEmployee, setEditEmployee] = useState(false);
    const [uploadBulkUsers, setUploadBulkUsers] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [employeeList, setEmployeeList] = useState([]);
    const [filteredEmployess, setFilteredEmployess] = useState([]);
    const [userid, setUserid] = useState(0);
    const [message, setMessage] = useState("");
    const [error, setError] = useState(false);
    const [companyInfo, setCompanyInfo] = useState({});
    const [success, setSuccess] = useState(false);
    const [loggedUser, setLoggedUser] = useState(0);
    const responseSuccess = 1;
    const employeeColumn = [
        { name: "First Name" },
        { name: "Last Name" },
        { name: "Email" },
        { name: "Phone Number" },
        { name: "Status" },
        { name: "Action" },
    ];

    useEffect(() => {
        setLoggedUser(parseInt(TokenService.getUserId()));
        getAllEmployees(TokenService.getUserId());
        getCompanyInfo(parseInt(TokenService.getUserId()));
    }, []);


    const getAllEmployees = async (userId) => {
        CoperateUser.getAllEmployees(parseInt(userId)).then((response) => {
            if (response?.data?.status_code === responseSuccess) {
                    const list = _.get(response, 'data.data', []);
                    setEmployeeList(list);
                    setFilteredEmployess(list);
            } else {
                //    setErrormessage(response.data.message);
                //    setUpdateError(true);
            }
        }).catch((error) => { console.error(error) });
    }


    const searchInput = (value) => {
        const filteredData = employeeList?.filter(record =>
            record?.first_name?.toLowerCase().includes(value?.toLowerCase()) || record?.last_name?.toLowerCase().includes(value?.toLowerCase())||record.phone_number.toLowerCase().includes(value.toLowerCase()) || record?.email?.toLowerCase().includes(value?.toLowerCase())
        );
        setFilteredEmployess(filteredData);
    }

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    const getCompanyInfo = (userId) => {
        CoperateUser.getCompanyInfo(userId).then(response => {

            if (response?.data?.status_code == responseSuccess) {
                setCompanyInfo(response?.data?.data?.details);
            } else {

            }
        }).catch((error) => console.log(error));
    }



    const employeeStatusUpdate = (userId, status) => {
        CoperateUser.changeEmployeeStatus(userId, status).then((response) => {
            if (response?.data?.status_code == responseSuccess) {
                getAllEmployees(loggedUser);
                toast.success('Status updated successfully', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

            } else {
                toast.error(response?.data?.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        }).catch((error) => { console.error(error) });
    }


    const handleSwitch = (_, userId) => {
        const list = filteredEmployess?.map((data) => {
            if (data?.user_id == userId) {
                const currentState = data?.user_status;
                const status = currentState == 1 ? 0 : 1;
                employeeStatusUpdate(userId, status);
                return { ...data, status }
            }
            return data;
        });
        setEmployeeList(list);
    }

    const editButtonClick = (userId) => {
        setEditEmployee(true);
        setUserid(userId);
    }


    const deleteButtonClick = (userId) => {
        setConfirmDelete(true);
        setUserid(userId);
    }

    const deleteEmployee = (userId) => {
        CoperateUser.deleteEmployee(userId).then((response) => {
            if (response?.data?.status_code == responseSuccess) {
                getAllEmployees(loggedUser);
                setMessage(response?.data?.message);
                setSuccess(true);
                setConfirmDelete(false);

            } else {
                setMessage(response?.data?.message);
                setError(true);
            }
        }).catch((error) => { console.log(error) });
    }




    return (<Box className="page-main-continer">
        <Box className="content-container">
            <Box className="content-wrap">
                <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />

                <Box className="title-wrap">
                    <PageTitleComponent title="Employees" sub={"Maximum employees " + companyInfo?.no_of_users + " only"} />

                    <Box p={1} >
                        <Box className="text-info-section">
                            <Box className="text-wrap">
                                <p>Total employees added - {companyInfo?.filled_emp_count} <br /> No of empty slots - {companyInfo?.no_of_users - companyInfo?.filled_emp_count}  </p>
                            </Box>
                        </Box>

                        <Box className="button-n-search-section">
                            <Grid container justifyContent="space-between" alignItems={"center"} spacing={1} maxWidth={"96%"}>
                                <Grid item >
                                    <Grid container spacing={1} justifyContent={"center"} >
                                        <Grid item className="button-wrap"><TextIconButtonComponent icon="accountBox" buttontext="Add User" buttonwidth="200px" handleClick={() => setAddEmployee(true)} /></Grid>
                                        <Grid item className="button-wrap"><TextIconButtonComponent icon="uploadFile" buttontext="Upload Bulk Users" buttonwidth="200px" handleClick={() => {setUploadBulkUsers(true)}} /></Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <SearchComponent getSearchValue={searchInput} />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
                <Box className="table-wrap">
                    <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
                        <TableContainer component={Paper} sx={{ maxHeight: 850 }}>
                            <Table stickyHeader aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        {employeeColumn.map((column, index) => (
                                            <StyledTableCell align="center" key={index}>{column.name}</StyledTableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredEmployess?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, index) => (
                                        <StyledTableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="center" component="th" scope="row">
                                                {data?.first_name}
                                            </TableCell>
                                            <TableCell align="center">{data?.last_name}</TableCell>
                                            <TableCell align="center">{data?.email}</TableCell>
                                            <TableCell align="center">{data?.phone_number}</TableCell>

                                            <TableCell align="center"><SwitchComponent checked={data?.user_status == 1 ? true : false} key={data?.user_id}
                                                onChange={() => {
                                                    handleSwitch(index, data?.user_id);
                                                }} inputProps={{ 'aria-label': 'controlled' }} /></TableCell>
                                            <TableCell align="center">
                                                <Box sx={{ display: "flex", gap: 0.5}}>
                                                    <Box><IconButtonComponent type="edit" buttonClick={() => { editButtonClick(data?.user_id) }} /></Box>
                                                    <Box><IconButtonComponent type="delete" buttonClick={() => { deleteButtonClick(data?.user_id) }} /></Box>
                                                </Box></TableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={filteredEmployess.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Box>
                </Box>

            </Box>
        </Box>
        <UpdateEmployee open={editEmployee} onClose={() => { setEditEmployee(false) }} userId={userid} getAllEmployees={getAllEmployees} />
        <AddEmployeeForm open={addEmployee} onClose={() => { setAddEmployee(false) }} getAllEmployees={getAllEmployees} />
        <UploadBulkUsers open={uploadBulkUsers} onClose={() => { setUploadBulkUsers(false); }} getAllEmployees={getAllEmployees}  />
        <AlertComponent description={message} buttontext="OK" type="error" open={error} onClose={() => { setError(false); setConfirmDelete(false) }} />
        <AlertComponent description={message} buttontext="OK" type="success" open={success} onClose={() => { setSuccess(false) }} />
        <AlertComponent title="Confirm" description="Are you sure you want to delete this user? " buttontext="Yes" buttontext2="No" type="info" open={confirmDelete} onClose={() => { setConfirmDelete(false) }} handleButtonClick={() => { deleteEmployee(userid) }} />

    </Box>);
}

export default Employees;