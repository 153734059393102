import * as yup from 'yup';

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
// const phoneNoRules = /^\d+$/;
const nameRules = /^[A-Za-z]+$/;



const minValue = (value) => {
    return value;
};

const LoginSectionValidation = yup.object().shape({
    username: yup.string().email("Please enter a valid email!").required("Please enter your email!"),
    password: yup
        .string()
        .required("Please enter your password!"),
});

const EmailValidation = yup.object().shape({
    username: yup.string().email("Please enter a valid email!").required("Please enter your email!"),
});

const RestPasswordValidation = yup.object().shape({
    password: yup
        .string()
        .min(8)
        .matches(passwordRules, { message: "Please create a strong password!" })
        .required("Please enter your password!"),
    confirmPassword: yup.string().oneOf([yup.ref("password")], "Passwords do not match!")
});

const ChangePassword = yup.object().shape({
    currentPassword: yup
        .string()
        .required("Please enter your password!"),
    newPassword: yup
        .string()
        .min(8)
        .matches(passwordRules, { message: "Please create a strong password!" })
        .required("Please enter your password!"),
    confirmPassword: yup.string().oneOf([yup.ref("newPassword")], "Passwords do not match!")
});

const CoperateClient = (minValue) => {
    return yup.object().shape({
        companyName: yup.string().required("Please enter a company name!"),
        redemptionCode: yup.string().required("Please enter redemption code!"),
        contactPerson: yup.string().required("Please enter a contact person!"),
        contactNumber: yup.string().min(7, 'Must be at least 07 characters').required("Please enter a contact number!"),
        email: yup.string().email("Please enter a valid email!").required("Please enter your email!"),
        noOfUsers: yup.number().typeError("Only numbers are allowed!").min(minValue, `Value must be greater than ${minValue}`).required("Please add no of users!"),
        address: yup.string().required("Please enter an address!")
    });
}

const Employee = yup.object().shape({
    firstName: yup.string().matches(nameRules, 'Only letters are allowed').required('Input is required'),
    lastName: yup.string().matches(nameRules, 'Only letters are allowed').required('Input is required'),
    email: yup.string().email("Please enter a valid email!").required("Please enter your email!"),
    contactNumber: yup.string().min(7, 'Must be at least 07 characters').required("Please enter a contact number!"),
    address: yup.string().required("Please enter an address!")
});

const CompanyInfomation = yup.object().shape({
    companyName: yup.string().required("Please enter a company name!"),
    address: yup.string().required("Please enter an address!"),
    contactNumber: yup.string().min(7, 'Must be at least 07 characters').required("Please enter a contact number!"),
    // file: yup.object().required("Please insert a logo")
});




const Validation = {
    LoginSectionValidation,
    EmailValidation,
    RestPasswordValidation,
    ChangePassword,
    CoperateClient,
    CompanyInfomation,
    Employee,
    minValue
}

export default Validation;
