import React from "react";
import { Button } from "@mui/material";
import { styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import AddIcon from '@mui/icons-material/Add';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import UploadFileIcon from '@mui/icons-material/UploadFile';


const TextIconButtonComponent = ({ buttontext, buttonwidth, handleClick, icon }) => {
  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(purple[500]),
    // backgroundColor: "#657ee4",
    backgroundColor: "#657ee4",
    textTransform: "capitalize",
    borderRadius: 5,
    fontFamily: '"Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    width: buttonwidth,
    '&:hover': {
      backgroundColor: "#657ee4",
      boxShadow: "0 2px 7px rgba(0, 0, 0, 0.28)",
      cursor: "pointer",
    },

  }));

  return (
    <>
      {icon === "add" ? <ColorButton variant="contained" startIcon={<AddIcon />} onClick={handleClick}>{buttontext}</ColorButton> : null}

      {icon === "edit" ? <ColorButton variant="contained" startIcon={<EditRoundedIcon />} onClick={handleClick}>{buttontext}</ColorButton> : null}

      {icon === "accountBox" ? <ColorButton variant="contained" startIcon={<AccountBoxIcon />} onClick={handleClick}>{buttontext}</ColorButton> : null}

      {icon === "uploadFile" ? <ColorButton variant="contained" startIcon={<UploadFileIcon />} onClick={handleClick}>{buttontext}</ColorButton> : null}


    </>
  )
}

export default TextIconButtonComponent