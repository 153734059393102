import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./styles/style.scss";
import { Box } from "@mui/material";

const DatePickerComponent = (props) => {
   const {label} = props;
  
return(<>
   <p  sx={{fontWeight:"bold"}}>
                {label}
            </p>
    <DatePicker className="form-control" placeholderText="Pick date" {...props} />
</>);
}

export default DatePickerComponent;