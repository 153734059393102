import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import BoxComponent from "./components/boxComponent/index";
// import ButtonComponent from "../../componets/form/buttonComponent/index";
import AlertComponent from "../../componets/alert";
import Images from "../../assets/images";
import PageTitleComponent from "../../componets/pageTitle";
import SuperAdmin from "../../services/superAdmin/superAdmin";

// style
import "./styles/style.scss";
import TokenService from "../../services/axios/token";





function Dashboard() {
    const [openAlert, setOpenAlert] = useState(false);
    const [clients, setClients] = useState(0);
    const [users, setUsers] = useState(false);
    const responseSuccess = 1;
    useEffect(()=>{
        getDashboardInfo(TokenService.getUserId("user_id"));
    },[]);

    const getDashboardInfo = async (userId) => {
        SuperAdmin.dashboardDetailsApi(userId).then((response) => {
            if (response.data.status_code === responseSuccess) {
                setUsers(response?.data?.data?.private_user_count)
                setClients(response?.data?.data?.client_count)
            } else {
            }
        }).catch((error) => { console.error(error) });
    }

    const closeAlert = () => {
        setOpenAlert(false)
    }

    return (
        <Box height={"100vh"}>
            <Box className="content-container">
               <Box className="content-wrap">
                    <Box className="title-wrap"><PageTitleComponent title="Dashboard"/></Box>
                    <Grid container justifyContent={"space-around"}>

                        <Grid item>
                            <Box className="card-item">
                                <BoxComponent title="Private Users" value={users} icon={Images.handshake} />
                            </Box>

                        </Grid>
                        <Grid item>
                            <Box className="card-item">
                                <BoxComponent title="Corporate Clients" value={clients} icon={Images.clientIcon} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
             


            <AlertComponent description="Are you sure, you want to logout?" buttontext="Yes" buttontext2="No" type="info" open={openAlert} onClose={closeAlert} />
        </Box>


    )
}
export default Dashboard;