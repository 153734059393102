import React from "react";
import { Box} from "@mui/material";


// styles
import "./styles/style.scss";

const PageTitleComponent = ({title, sub}) => {
    return (<Box ml={"2.8%"}>
        <h4>{title}</h4>
        <p style={{color:"#a8a8a8"}}>{sub}</p>
    </Box>);
}

export default PageTitleComponent;