import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import InputComponent from "../../componets/form/inputComponent";
import ButtonComponent from "../../componets/form/buttonComponent";
import PageTitleComponent from "../../componets/pageTitle";
import CoperateUser from "../../services/coperateAdmin/coperateUser";
import { useFormik } from "formik";
import TokenService from "../../services/axios/token";
import { updateCompanyInfoApend } from "./FormData/Method";



// styles
import "./styles/style.scss";
import Validation from "../../utils/Validation";
import AlertComponent from "../../componets/alert";
import PhoneNumberComponent from "../../componets/form/phoneNumberComponent";
import { formatPhoneNumberIntl } from "react-phone-number-input";


const CompanyInformation = () => {
    const [pressEdit, setPressEdit] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [successAlert, setSuccessAlert] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);
    const [baseUrl, setBaseUrl] = useState("");
    const [companyInfo, setCompanyInfo] = useState({});
    const [currentUserId, setCurrentId] = useState(0);
    const responseSuccess = 1;
    const [Info, setInfo] = React.useState({
        companyName: "",
        contactNumber: "",
        address: "",
        file: { file: null }
    });
    const [errors, setErrors] = React.useState({});
    const [touchedFields, setTouchedFields] = React.useState({});

    useEffect(() => {
        setCurrentId(parseInt(TokenService.getUserId()));
        getCompanyInfo(parseInt(TokenService.getUserId()));
    }, []);

    const getCompanyInfo = (userId) => {
        CoperateUser.getCompanyInfo(userId).then(response => {
            if (response?.data?.status_code == responseSuccess) {
                setBaseUrl(response?.data?.data?.base_url);
                console.log("bbbb: ", response?.data?.data?.details?.mobile_number)
                setInfo({
                    companyName: response?.data?.data?.details?.company_name,
                    contactNumber: response?.data?.data?.details?.mobile_number,
                    address: response?.data?.data?.details?.address,
                    file: { file: null }
                })
                setCompanyInfo(response?.data?.data?.details);
            } else {
                pressEdit(false);
                setSuccessAlert(true);
            }
        }).catch((error) => console.log(error));
    }

    const updateCompanyInfo = (userId, companyName, address, phoneNo, file) => {
        CoperateUser.updateCompanyInfo(userId, companyName, address, phoneNo, file).then(response => {
            if (response?.data?.status_code == responseSuccess) {
                getCompanyInfo(parseInt(TokenService.getUserId()));
                setSuccessMsg(response?.data?.message);
                setSuccessAlert(true);
                setPressEdit(false);
            } else {
                setErrorMsg(response?.data?.message);
                setErrorAlert(true);
            }
        }).catch((error) => console.error(error));
    }




    const handleFieldBlur = (fieldName) => {
        setTouchedFields((prevTouchedFields) => ({
            ...prevTouchedFields,
            [fieldName]: true,
        }));
    };


    const handleChange = async (field, value) => {
        setInfo((Info) => ({
            ...Info,
            [field]: value
        }));

        setErrors((Info) => ({
            ...Info,
            [field]: '',
        }));

        try {
            await Validation.Employee.validate(Info, { abortEarly: false });
            // setCheckErrors(false);

        } catch (validationErrors) {
            const errorsObj = {};
            validationErrors.inner.forEach((error) => {
                errorsObj[error.path] = error.message;
            });
            setErrors(errorsObj);
        }


    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await Validation.CompanyInfomation.validate(Info, { abortEarly: false });
            console.log(Info)
            updateCompanyInfo(updateCompanyInfoApend(currentUserId, Info.companyName, Info.address, Info.contactNumber, Info.file));
        } catch (validationErrors) {
            const errorsObj = {};
            validationErrors.inner.forEach((error) => {
                errorsObj[error.path] = error.message;
            });
            setErrors(errorsObj);
        }
    };


    return (<Box className="page-main-continer">
        <Box className="content-container">
            <Box className="content-wrap">
                <Box className="pc-top-section">
                    <PageTitleComponent title="Company Information" />
                </Box>

                <Box className="form-section">
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={5} justifyContent={"center"} alignItems={"center"} >
                            <Grid item>
                                <Box className="logo-section">
                                    {pressEdit ? <Box className="company-logo">
                                        <img src={baseUrl + companyInfo?.logo} alt="Company-Logo" />
                                    </Box> : <Box className="company-logo">
                                        <img src={baseUrl + companyInfo?.logo} alt="Company-Logo" />
                                    </Box>}
                                </Box>
                            </Grid>
                            <Grid item>

                                <Box className="form-content-section">

                                    <Box>
                                        <InputComponent
                                            type="text"
                                            label="Company Name"
                                            placeholder="Company Name"
                                            name="companyName"
                                            value={Info.companyName}
                                            onChange={(e) => handleChange('companyName', e.target.value)}
                                            onBlur={() => handleFieldBlur('companyName')}
                                            error={touchedFields.companyName && errors.companyName}
                                            readOnly={pressEdit ? null : true} />
                                    </Box>
                                    <Box>
                                        <InputComponent
                                            type="text"
                                            label="Address"
                                            placeholder="Address"
                                            name="address"
                                            value={Info.address}
                                            onChange={(e) => handleChange('address', e.target.value)}
                                            onBlur={() => handleFieldBlur('address')}
                                            error={touchedFields.address && errors.address}
                                            readOnly={pressEdit ? null : true} />
                                    </Box>
                                    <Box>


                                        <PhoneNumberComponent
                                            label="Contact Number"
                                            placeholder="Contact Number"
                                            defaultCountry="GB"
                                            name="contactNumber"
                                            value={Info.contactNumber}
                                            onChange={(e) => handleChange('contactNumber', formatPhoneNumberIntl(e))}
                                            onBlur={() => handleFieldBlur('contactNumber')}
                                            error={touchedFields.contactNumber && errors.contactNumber}
                                            readOnly={pressEdit ? null : true}
                                        />
                                    </Box>

                                    {pressEdit ? (<>

                                        <Box>
                                            <InputComponent
                                                type="file"
                                                label="Company Logo"
                                                placeholder="Company Logo"
                                                name="file"
                                                onChange={(e) => handleChange('file', e.currentTarget.files[0])}
                                                onBlur={() => handleFieldBlur('file')}
                                                error={touchedFields.file && errors.file}

                                            />
                                        </Box>

                                        <Box className="button-group-wrap">
                                            <Box className="button-group-item">
                                                <ButtonComponent type="submit" buttontext="Save" buttonwidth={130} buttoncolor={"#657ee4"} onClick={handleSubmit} />
                                            </Box>
                                            <Box className="button-group-item">
                                                <ButtonComponent buttontext="Cancel" buttonwidth={130} buttoncolor="#616D89" onClick={() => setPressEdit(false)} />
                                            </Box>
                                        </Box>

                                    </>) : <Box className="edit-btn-container">
                                        <Box className="edit-btn-wrap">
                                            <ButtonComponent buttontext="Edit" buttonwidth={150} onClick={() => setPressEdit(true)} buttoncolor={"#657ee4"} />
                                        </Box>
                                    </Box>

                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </Box>

            </Box>
        </Box>

        <AlertComponent description={successMsg} buttontext="OK" type="success" open={successAlert} onClose={() => { setSuccessAlert(false) }} />
        <AlertComponent description={errorMsg} buttontext="OK" type="error" open={errorAlert} onClose={() => { setErrorAlert(false) }} />

    </Box>);
}

export default CompanyInformation;