export const updateCompanyInfoApend = (userId, companyName, address, mobileNumber, file) => {
    console.log(userId, companyName, address, mobileNumber, file)
    const formData = new FormData();
      formData.append('company_name', companyName);
      formData.append('address', address);
      formData.append('mobile_number', mobileNumber);
      formData.append('user_id', userId);
      formData.append('logo', file);
  
      return formData;
  }

