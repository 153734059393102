import * as React from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Box, Typography } from "@mui/material";
import InputComponent from "../../../../componets/form/inputComponent";
import IconButtonComponent from "../../../../componets/form/iconButtonComponent";
import ButtonComponent from "../../../../componets/form/buttonComponent"
import template from "../../../../assets/file/Bulk_Employee_Upload_Excel_format.xlsx";
import { useFormik } from "formik";
import CoperateUser from "../../../../services/coperateAdmin/coperateUser";
import { uploadSheetApend } from "./FormData/Method";
import AlertComponent from "../../../../componets/alert";
import TokenService from "../../../../services/axios/token";
// styles
import "./style/style.scss";



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const UploadBulkUsers = ({ open, onClose, getAllEmployees }) => {
    const responseSuccess = 1;
    const [successAlert, setSuccessAlert] = React.useState(false);
    const [errorAlert, setErrorAlert] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [successMsg, setSuccessMsg] = React.useState("");
    const [duplicateEmails, setDuplicateEmails] = React.useState([]);


    React.useEffect(() => { }, [getAllEmployees]);

    const downloadExcelTemplate = () => {
        const fileUrl = template;
        const link = document.createElement('a');
        link.href = fileUrl;
        const fileName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const uploadExcelSheet = (file) => {
        CoperateUser.uploadBulkEmployees(file).then(response => {
            console.log(response);
            if (response?.data?.status_code === responseSuccess) {
                setSuccessMsg(response?.data?.message);
                setSuccessAlert(true);
                getAllEmployees(TokenService.getUserId());
            } else {
                setErrorMsg(response?.data?.message);
                setDuplicateEmails(response?.data?.data?.duplicate_emails);
                setErrorAlert(true);
            }
        }).catch((error) => console.error(error));
    }


    const onSubmit = async (value) => {
        uploadExcelSheet(uploadSheetApend(value.file));
    }

    const { handleBlur, handleSubmit, setFieldValue, errors, touched } = useFormik({
        initialValues: {
            file: { file: null }
        },
        onSubmit,
        enableReinitialize: true,
    });

    return (
        <Dialog
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogActions>
                <IconButtonComponent type="close" buttonClick={onClose} />
            </DialogActions>
            <DialogTitle><Typography variant="subitle">Upload bulk Employees</Typography></DialogTitle>

            <DialogContent>

                <form onSubmit={handleSubmit}>
                    <Box className="form-content">

                        <Box className="form-item">
                            <InputComponent
                                type="file"
                                placeholder="Company Logo"
                                name="file"
                                onChange={(e) => {
                                    const file = e.currentTarget.files[0];
                                    setFieldValue('file', file);
                                }}
                                onBlur={handleBlur}
                                error={touched.file && errors.file} />
                        </Box>
                        <Box><p>Supported file types:- .xlsx , .xls</p></Box>

                        <Box className="form-item">
                            <ButtonComponent buttontext="Download sample sheet" buttoncolor="#657ee4" buttonwidth={240} onClick={downloadExcelTemplate} />
                        </Box>



                        <Box className="button-group">
                            <Box className="button-wrap">
                                <ButtonComponent buttontext="Upload" buttoncolor="#657ee4" buttonwidth={100} onClick={handleSubmit} />
                            </Box>
                            <Box className="button-wrap">
                                <ButtonComponent buttontext="Cancel" buttoncolor="#616D89" buttonwidth={100} onClick={() => { onClose();  window.location.reload();}} />
                            </Box>
                        </Box>

                    </Box>

                </form>
            </DialogContent>

            <AlertComponent description={successMsg} buttontext="OK" type="success" open={successAlert} onClose={() => { setSuccessAlert(false); onClose() }} />
            <AlertComponent description={errorMsg} buttontext="OK" type="error-bulk-user" data={duplicateEmails} open={errorAlert} onClose={() => { 
                setErrorAlert(false); 
                window.location.reload();
                onClose() }} />



        </Dialog>
    );
}
export default UploadBulkUsers;