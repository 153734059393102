import baseApi from "../axios/axios";
import { paths } from "../paths";

const getAllPrivateUsersApi = async () => {
    return await baseApi.instance.get(paths.allPrivateUsersDetails);
}

const getPrivateUserApi = async (userId) => {
    return await baseApi.instance.post(paths.privateUserDetails, { user_id: userId });
}


const deletePrivateUserApi = async (userId) => {
    return await baseApi.instance.post(paths.deletePrivateUser, { user_id: userId });
}


const changePrivateUserStatusApi = async (userId, status) => {
    return await baseApi.instance.post(paths.changeUserAccountStatus, { user_id: userId, status: status });
}


const changePasswordApi = async (currentPassword, newPassword, newPasswordComfirm) => {
    return await baseApi.instance.post(paths.changePassword, { current_password: currentPassword, new_password: newPassword, new_password_confirmation: newPasswordComfirm });
}

const allCoperateClientsApi = async () => {
    return await baseApi.instance.get(paths.allCoperateClients);
}


const coperateClientApi = async (userid) => {
    return await baseApi.instance.post(paths.coperateClient, { user_id: userid });
}

const createCoperateClientApi = async (companyName, contactPerson, redemptionCode, email, contactNumber, address, noOfUsers, subscriptionDateFrom,subscriptionDateTo ) => {
    return await baseApi.instance.post(paths.createCoparateClient, {
        company_name: companyName,
        contact_person: contactPerson,
        redemption_code: redemptionCode,
        email: email,
        contact_number: contactNumber,
        address: address,
        no_of_users: noOfUsers,
    });
}


const deleteCoperateCilent = async (userId) => {
    return await baseApi.instance.post(paths.deleteCoparateClient, { user_id: userId });
}


const updateCoperateCilentApi = async (userId, clientId, companyName, contactPerson, redemptionCode, email, contactNumber, address, noOfUsers, subscriptionDateFrom, subscriptionDateTo) => {
    return await baseApi.instance.post(paths.updateCoparateClient, { 
    user_id:userId,
    client_id:clientId,
    company_name:companyName,
    contact_person:contactPerson,
    redemption_code:redemptionCode,
    email:email,
    contact_number:contactNumber,
    address:address,
    no_of_users:noOfUsers,
    subscription_from:subscriptionDateFrom,
    subscription_to:subscriptionDateTo
 });
}
const changeCoperateClientStatus = async (userId, status) => {
    return await baseApi.instance.post(paths.changeCoperateClientStatus, { user_id: userId , status:status});
}

const dashboardDetailsApi = async (userId) => {
    return await baseApi.instance.get(paths.dashboardDetails, { user_id: userId });
}

const SuperAdmin = {
    dashboardDetailsApi,
    changeCoperateClientStatus,
    updateCoperateCilentApi,
    deleteCoperateCilent,
    createCoperateClientApi,
    coperateClientApi,
    allCoperateClientsApi,
    changePasswordApi,
    changePrivateUserStatusApi,
    deletePrivateUserApi,
    getPrivateUserApi,
    getAllPrivateUsersApi
};

export default SuperAdmin;