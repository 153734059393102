import * as React from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Box, Grid } from "@mui/material";
import InputComponent from "../../../../componets/form/inputComponent";
import IconButtonComponent from "../../../../componets/form/iconButtonComponent";
import ButtonComponent from "../../../../componets/form/buttonComponent";
import CoperateUser from "../../../../services/coperateAdmin/coperateUser";
import DatePickerComponent from "../../../../componets/form/datePickerComponent/index";
import PhoneNumberComponent from "../../../../componets/form/phoneNumberComponent"
import Validation from "../../../../utils/Validation";
import DropDownComponent from "../../../../componets/form/dropDownComponent";
import TokenService from "../../../../services/axios/token";

// styles
import "./styles/style.scss";
import AlertComponent from "../../../../componets/alert";
import moment from "moment";
import { formatPhoneNumberIntl } from "react-phone-number-input";





const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddEmployeeForm = ({ open, onClose = () => { }, getAllEmployees }) => {
    const responseSuccess = 1;
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(false);
    const [currentUser, setCurrentUser] = React.useState(0);
    const [employeeData, setEmployeeData] = React.useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNo: "",
        address: "",
        gender: "",
        dob: ""
    });
    const [errors, setErrors] = React.useState({});
    const [touchedFields, setTouchedFields] = React.useState({});

    React.useEffect(() => {
        setCurrentUser(TokenService.getUserId);
    }, [getAllEmployees])

    const AddEmployee = (firstName, lastName, email, phoneNo, address, gender, dob) => {
        CoperateUser.createCoperateEmployee(firstName, lastName, email, phoneNo.split(" ").join(""), address, gender, moment(dob).format('Y-MM-D'),phoneNo.split(/\s/)[0]).then((response) => {
            if (response?.data?.status_code == responseSuccess) {
                setSuccess(true);
                getAllEmployees(currentUser);
            } else {
                setErrorMessage(response?.data?.message);
                setError(true);
            }
        }).catch((error) => { console.log(error) });
    }



    const handleFieldBlur = (fieldName) => {
        setTouchedFields((prevTouchedFields) => ({
            ...prevTouchedFields,
            [fieldName]: true,
        }));
    };


    const handleChange = async (field, value) => {
        setEmployeeData((employeeData) => ({
            ...employeeData,
            [field]: value
        }));

        setErrors((employeeData) => ({
            ...employeeData,
            [field]: '',
        }));

        try {
            await Validation.Employee.validate(employeeData, { abortEarly: false });
        } catch (validationErrors) {
            const errorsObj = {};
            validationErrors.inner.forEach((error) => {
                errorsObj[error.path] = error.message;
            });

            setErrors(errorsObj);
        }


    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await Validation.Employee.validate(employeeData, { abortEarly: false });
            AddEmployee(employeeData?.firstName, employeeData?.lastName, employeeData?.email, employeeData?.contactNumber, employeeData?.address, employeeData?.gender, employeeData?.dob);
        } catch (validationErrors) {
            const errorsObj = {};
            validationErrors.inner.forEach((error) => {
                errorsObj[error.path] = error.message;
            });
            if (errorsObj?.firstName || errorsObj?.lastName || errorsObj?.email || errorsObj?.contactNumber || errorsObj?.address) {
                setErrorMessage("Please insert Employee details before submitting!");
                setError(true);
            }
            setErrors(errorsObj);
        }
    };
    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogActions>
                    <IconButtonComponent type="close" buttonClick={onClose} />
                </DialogActions>
                <DialogTitle>Add Employee</DialogTitle>

                <DialogContent>
                    <Box>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Box className="form-content">
                                        <Box className="form-item">
                                            <InputComponent
                                                label="First Name"
                                                type="text"
                                                placeholder="First Name"
                                                name="firstName"
                                                value={employeeData.firstName}
                                                onChange={(e) => handleChange('firstName', e.target.value)}
                                                onBlur={() => handleFieldBlur('firstName')}
                                                error={touchedFields.firstName && errors.firstName} />
                                        </Box>
                                        <Box className="form-item">
                                            <InputComponent
                                                label="Email"
                                                type="text"
                                                placeholder="Email"
                                                name="email"
                                                value={employeeData.email}
                                                onChange={(e) => handleChange('email', e.target.value)}
                                                onBlur={() => handleFieldBlur('email')}
                                                error={touchedFields.email && errors.email}
                                            />
                                        </Box>
                                        <Box className="form-item">
                                            <PhoneNumberComponent
                                                placeholder="Contact Number"
                                                defaultCountry="GB"
                                                name="contactNumber"
                                                value={employeeData.contactNumber}
                                                onChange={(e) => handleChange('contactNumber', formatPhoneNumberIntl(e))}
                                                onBlur={() => handleFieldBlur('contactNumber')}
                                                error={touchedFields.contactNumber && errors.contactNumber}
                                            />
                                        </Box>




                                        <Box className="form-item">
                                            <DatePickerComponent
                                                label="Dob"
                                                id="dob"
                                                name="dob"
                                                dateFormat="dd-MM-yyyy"
                                                selected={employeeData?.dob}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                onBlur={() => handleFieldBlur('dob')}
                                                onChange={(e) => handleChange('dob', e)}
                                            />

                                        </Box>


                                    </Box>
                                </Grid>
                                <Grid item>
                                    <Box className="form-content">
                                        <Box className="form-item">
                                            <InputComponent label="Last Name"
                                                type="text"
                                                placeholder="Last Name"
                                                name="lastName"
                                                value={employeeData.lastName}
                                                onChange={(e) => handleChange('lastName', e.target.value)}
                                                onBlur={() => handleFieldBlur('lastName')}
                                                error={touchedFields.lastName && errors.lastName} />
                                        </Box>


                                        <Box className="form-item">
                                            <DropDownComponent
                                                labelAbove="Gender"
                                                name={"gender"}
                                                value={employeeData.gender}
                                                onChange={(e) => handleChange('gender', e.target.value)}
                                                onBlur={() => handleFieldBlur('gender')}
                                                error={touchedFields.gender && errors.gender}
                                                fullWidth
                                                size="small"
                                            />

                                        </Box>

                                        <Box className="form-item">
                                            <InputComponent
                                                label="Address"
                                                type="text"
                                                placeholder="Address"
                                                name="address"
                                                value={employeeData.address}
                                                onChange={(e) => handleChange('address', e.target.value)}
                                                onBlur={() => handleFieldBlur('address')}
                                                error={touchedFields.address && errors.address}
                                                textboxarea={"textArea"}
                                                rows={8} />
                                        </Box>


                                        <Box className="button-group">
                                            <Box className="button-wrap">
                                                <ButtonComponent type="submit" buttoncolor="#657ee4" buttontext="Save" buttonwidth={100} onClick={handleSubmit} />
                                            </Box>
                                            <Box className="button-wrap">
                                                <ButtonComponent buttontext="Cancel" buttonwidth={100} buttoncolor="#616D89" onClick={() => {
                                                    onClose(); setEmployeeData({
                                                        firstName: "",
                                                        lastName: "",
                                                        email: "",
                                                        phoneNo: "",
                                                        address: "",
                                                        gender: "",
                                                        dob: ""
                                                    });
                                                    setErrors({});
                                                }} />
                                            </Box>
                                        </Box>

                                    </Box>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>

                </DialogContent>

            </Dialog>

            <AlertComponent title={"Success"} description={"New User Created Successfully."} buttontext={"OK"} type={"success"} open={success} onClose={() => { setSuccess(false); onClose() }} />
            <AlertComponent title={"Error"} description={errorMessage} buttontext={"OK"} type={"error"} open={error} onClose={() => setError(false)} />

        </>

    );
}
export default AddEmployeeForm;