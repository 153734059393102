import React, { useState} from "react";
import { Box, Typography } from "@mui/material";
import InputComponent from "../../../../componets/form/inputComponent";
import ButtonComponent from "../../../../componets/form/buttonComponent";
import "../../style/style.scss";
import AlertComponent from "../../../../componets/alert";
import { useFormik } from "formik";
import Authapi from "../../../../services/login/login";
import TokenService from "../../../../services/axios/token";
import { useNavigate } from "react-router-dom";
import Validation from "../../../../utils/Validation";

const SignInComponent = ({ setForgotPasswordState }) => {
    const [checkUserAlert, setCheckUserAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();
    const superAdmin = 1;
    const authenticated = 1;


    const btnClick = () => {    
        setForgotPasswordState(2);
    }

    
    const onSubmit = (values) => {
        signIn(values.username, values.password)
    }

    const signIn = async (username, password) => {
        
        Authapi.signInApi(username, password).then((response) => {
            console.log(response.data)
            
            if (response.data.status_code === authenticated) {
                TokenService.setToken(response.data.data.access_token, response.data.data.user_role, response.data.data.user_id);
                if (response.data.data.user_role === superAdmin) {
                    navigate("/dashboard");
                    window.location.reload();
                } else {
                    navigate("/employees");
                    window.location.reload();
                }
            }else{
                setErrorMessage(response.data.message);
                setCheckUserAlert(true);
            }
        }).catch((error) => { console.error(error) });
    }


    const { values, handleBlur, handleChange, handleSubmit, errors, touched } = useFormik({
        initialValues: {
            username: "",
            password: ""

        },
        onSubmit,
        validationSchema: Validation.LoginSectionValidation
    });

    return (
        <Box>
            <Box className="text-wrap">
                <Box>
                    <h4>Login</h4>
                </Box>

                <Box className="text-description">
                    <p>Welcome back please login to your account.</p>
                </Box>
            </Box>
            <Box className="form-style">
                <form onSubmit={handleSubmit}>
                    <Box className="form-input-wrap">
                        <Box>
                            <InputComponent
                                type="text"
                                placeholder="Email"
                                name="username"
                                value={values.username}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.username && errors.username}
                            />

                        </Box>
                        <Box >
                            <InputComponent
                                type="password"
                                placeholder="Password"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.password &&  errors.password}
                            />
                        </Box>
                        <Box className="forgot-password">
                            <Typography variant="caption" onClick={btnClick}>Forgot Password ?</Typography>
                        </Box>
                        <Box className="btn-wrap">
                            <ButtonComponent type="submit" onClick={handleSubmit} buttontext="Sign in" buttonwidth={100} buttoncolor="#657ee4"/>
                        </Box>
                    </Box>

                </form>
            </Box>

            <AlertComponent title="Invaid Login" description={errorMessage} buttontext="OK"  type="error" open={checkUserAlert} onClose={() => setCheckUserAlert(false)}/>
        </Box>
    )
}

export default SignInComponent;