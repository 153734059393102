import React, { useEffect, useState } from "react";
import SearchComponent from "../../componets/search";
import TextIconButtonComponent from "../../componets/form/textIconButtonComponent";
import CooperateClientForm from "./component/form";
import PageTitleComponent from "../../componets/pageTitle";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, tableCellClasses } from "@mui/material";
import Paper from '@mui/material/Paper';
import SwitchComponent from "../../componets/form/switchComponent";
import IconButtonComponent from "../../componets/form/iconButtonComponent";
import { styled } from '@mui/material/styles';
import AlertComponent from "../../componets/alert";
import SuperAdmin from "../../services/superAdmin/superAdmin";
import CommonMethods from "../../utils/CommonMethods";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';




// style
import "./styles/style.scss";
import AddClient from "./component/form/AddClient";



// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//     [`&.${tableCellClasses.head}`]: {
//         backgroundColor: theme.palette.common.white,
//         color: theme.palette.common.black,
//     },
//     [`&.${tableCellClasses.body}`]: {
//         fontSize: 14,
//     },
// }));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));



const CooperateClients = () => {
    const [formStatus, setFormStatus] = useState(false);
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [clientList, setClientList] = useState([]);
    const [filteredClientList, setFilteredClientList] = useState([]);

    const [addClient, setAddClient] = useState(false);
    const [userid, setUserid] = useState(0);
    const [baseUrl, setBaseUrl] = useState("");
    const responseSuccess = 1;


    const coOperateClientColumn = [
        { name: "Logo" },
        { name: "Company Name" },
        { name: "Redemption Code" },
        { name: "No of Users" },
        { name: "Current Subscription Period" },
        { name: "Status" },
        { name: "Action" },
    ];

    useEffect(() => {
        getAllCoperateClients();
    }, []);

    const getAllCoperateClients = async () => {
        SuperAdmin.allCoperateClientsApi().then((response) => {
            if (response?.data?.status_code === responseSuccess) {
                setMessage(response?.data?.message)
                setBaseUrl(response?.data?.data?.base_url);
                setClientList(response?.data?.data?.clients);
                setFilteredClientList(response?.data?.data?.clients);
            } else {
                //    setErrormessage(response.data.message);
                //    setUpdateError(true);
            }
        }).catch((error) => { console.error(error) });
    }


    const updateCoparateClientStatus = async (userId, status) => {
        SuperAdmin.changeCoperateClientStatus(userId, status).then((response) => {
            if (response.data.status_code === responseSuccess) {
                getAllCoperateClients();
                toast.success('Status updated successfully', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
            }
        }).catch((error) => { console.error(error) });
    }

    const handleSwitch = (_, userId) => {
        const list = filteredClientList.map((data) => {
            if (data?.user_id == userId) {
                const currentState = data?.status;
                const status = currentState == 1 ? 0 : 1;
                updateCoparateClientStatus(userId, status);
                return { ...data, status }
            }
            return data;
        });
        setClientList(list);
    }

   

    const handleChangePage = (_, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    const closeForm = () => {
        setFormStatus(false);
    }
    const searchInput = (value) => {
        const filteredData = clientList.filter(record =>
            record?.company_name.toLowerCase().includes(value.toLowerCase()) || record?.redemption_code.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredClientList(filteredData);
    }

    const viewUserButtonClick = (userId) => {
        setFormStatus(true);
        setUserid(userId);
    }



    return (<Box className="content-container">
        <Box className="content-wrap">
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Box className="title-wrap">
                <PageTitleComponent title="Corporate Clients" />
            </Box>


            <Box className="add-n-search-section">
                <Box className="add-n-search-content-wrap">
                    <Box>
                        <TextIconButtonComponent buttontext="Add Client" icon="add" handleClick={() => setAddClient(true)} />
                    </Box>
                    <Box>
                        <SearchComponent getSearchValue={searchInput} />
                    </Box>
                </Box>
            </Box>


            <Box className="table-wrap">
                <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>

                    <TableContainer component={Paper} sx={{ maxHeight: 850 }}>
                        <Table stickyHeader aria-label="simple table">
                            <>
                                <TableHead>
                                    <TableRow>
                                        {coOperateClientColumn.map((column, index) => (
                                            <TableCell align="center" key={index}>{column.name}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredClientList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((client, index) => (
                                        <StyledTableRow
                                            key={client?.user_id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >

                                            <TableCell align="center" component="th" scope="row">
                                                <Box ><img src={baseUrl + client?.logo} alt="logo" style={{ objectFit: "cover", width: "55px", height: "55px" }} /></Box>

                                            </TableCell>
                                            <TableCell align="center" scope="row">
                                                {client?.company_name}
                                            </TableCell>
                                            <TableCell align="center">{client?.redemption_code}</TableCell>
                                            <TableCell align="center">{client?.filled_emp_count}/{client?.no_of_users}</TableCell>
                                            <TableCell align="center"><Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: 2 }}>
                                                <Box>{client?.start_date !== null ? CommonMethods.convertDate(client?.start_date) : ""}</Box>
                                                <p> - </p>
                                                <Box>{client?.start_date !== null ? CommonMethods.convertDate(client?.end_date) : ""}</Box>
                                            </Box></TableCell>
                                            <TableCell align="center"><SwitchComponent checked={client?.status == 1 ? true : false} key={client?.user_id}
                                                onChange={() => {
                                                    handleSwitch(index, client?.user_id);
                                                }} inputProps={{ 'aria-label': 'controlled' }} /></TableCell>
                                            <TableCell align="center"><Box><IconButtonComponent type="view" buttonClick={() => { viewUserButtonClick(client?.user_id) }} /></Box></TableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={clientList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>

            </Box>
        </Box>
        <AlertComponent title="Success" description={message} buttontext="OK" type="success" open={alert} onClose={() => { setAlert(false) }} />
        <CooperateClientForm open={formStatus} onClose={closeForm} userId={userid} getClientList={getAllCoperateClients} />
        <AddClient open={addClient} onClose={() => { setAddClient() }} getAllclients={getAllCoperateClients} />
    </Box>)
}

export default CooperateClients;    