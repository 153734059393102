import _default from "@mui/material/styles/identifier";

const setToken = (token, userRole, userId) => {
    localStorage.setItem("access_token", token);
    localStorage.setItem("user_role", userRole);
    localStorage.setItem("user_id", userId);
}

const getToken = () => {
    let token = "Bearer " + localStorage.getItem("access_token");
    return token;
}

const getUserRole = () => {
    let user = localStorage.getItem("user_role");
    return user;
}

const getUserId = () => {
    let id = localStorage.getItem("user_id");
    return id;
}

const removeToken = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_role");
}

const TokenService = {
    setToken,
    getToken,
    removeToken,
    getUserRole,
    getUserId
}

export default TokenService;

