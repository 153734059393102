import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SignIn from './layouts/loginLayout';
import LoggedInLayout from './layouts/LoggedInLayout';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import PrivateUsers from './pages/PrivateUsers';
import CooperateClients from './pages/CooperateClients';
import ChangePassword from './pages/ChangePassword';
import Employees from './pages/Employees';
import CompanyInformation from './pages/CompanyInformation';
import ResetPassword from './pages/ResetPassword';
import TokenService from './services/axios/token';
import PrivateRoutes from './utils/PrivateRoutes';
import { useEffect, useState } from 'react';
import CoperateAdmin from './utils/CoperateAdminRoutes';
import SuperAdmin from './utils/SuperAdminRoutes';




const theme = createTheme({
  palette: {
    primary: {
      main: "#5771ea",
    },
    secondary: {
      main: "#0r6ab4",
    },
  },
  typography: {
    fontFamily: `Montserrat`,
  },
  components: {
    MuiInput: {
      styleOverrides: {
        underline: {
          "&&::before": {
            borderBottom: "none",
          },
          "&&:hover::before": {
            borderBottom: "none",
          },
          "&&::after": {
            borderBottom: "none",
          },
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#fff",
          // boxShadow: "none",
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#657ee4",
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: "#657ee4",
        },
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&&:hover": {
            backgroundColor: "#ffffff36",
          },
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          // "&&:hover": {
          // },
          "&&:focused": {
            borderColor: 'red',
            borderWidth: 2,
          },

        }
      }
    }
  }
})

function App() {
  const [userRole, setUseRole] = useState(0);
  useEffect(() => {
    setUseRole(TokenService.getUserRole());
  }, []);

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path="/">


              {/* login Layout */}
              <Route element={<SignIn />}>
                <Route index element={<Login />} />
                <Route path='password/reset/:token/:email' element={<ResetPassword />} />

                <Route element={<PrivateRoutes />}>
                  <Route element={<LoggedInLayout adminType={userRole} />}>
                    <Route element={<SuperAdmin />}>
                      <Route path='dashboard' element={<Dashboard />} />
                      <Route path='private-users' element={<PrivateUsers />} />
                      <Route path='coperate-clients' element={<CooperateClients />} />
                    </Route>
                    <Route element={<CoperateAdmin />}>
                      <Route path='employees' element={<Employees/>} />
                      <Route path='company-information' element={<CompanyInformation />} />
                    </Route>
                    <Route path='change-password' element={<ChangePassword />} />
                  </Route>
                </Route>
                <Route path="*" element={<Login />} />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </>
  )
}


export default App
