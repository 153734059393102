import axios from "axios";
import TokenService from "./token";

const Commoninstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URI,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json"
    },
});
const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URI,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization" : TokenService.getToken("access_token")
    },
});

const mediaHeadersInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URI,
    headers: {
        "Content-Type": "multipart/form-data;",
        "Authorization" : TokenService.getToken("access_token")
    },
});


const API = {
    instance,
    Commoninstance,
    mediaHeadersInstance
} 

export default API;