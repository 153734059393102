import React from "react";
import { IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import LogoutIcon from '@mui/icons-material/Logout';
import EditIcon from '@mui/icons-material/Edit';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';


const IconButtonComponent = ({ type, buttonClick}) => {

    return (<>
        {type === "delete" ? (<IconButton onClick={buttonClick} aria-label="delete">
            <DeleteIcon sx={{color:"red"}}/>
        </IconButton>) : null}

        {type === "view" ?  (<IconButton  onClick={buttonClick} aria-label="visibility">
            <VisibilityIcon />
        </IconButton>) : null}

        {type === "logout" ?  (<IconButton size="large" onClick={buttonClick} aria-label="logout">
            <LogoutIcon />
        </IconButton>) : null}

        {type === "close" ?  (<IconButton size="large" onClick={buttonClick} aria-label="close">
            <CancelRoundedIcon sx={{color:"red"}}/>
        </IconButton>) : null}

        {type === "edit" ?  (<IconButton  onClick={buttonClick} aria-label="edit">
            <EditIcon />
        </IconButton>) : null}
    </>

    )
}
export default IconButtonComponent;