import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import InputComponent from "../../../../componets/form/inputComponent";
import ButtonComponent from "../../../../componets/form/buttonComponent";

// style 
import './style/style.scss';
import { useFormik } from "formik";
import Authapi from "../../../../services/login/login";
import Validation from "../../../../utils/Validation";
import AlertComponent from "../../../../componets/alert";

const ForgotPasswordComponent = ({ setForgotPasswordState }) => {
    const [openAlert, setOpenAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const backButtonPress = () => {
        setForgotPasswordState(1);
    }

    
    const onSubmit = (values) => {
        forgotPassword(values.username)
    }

    const forgotPassword = async (username) => {
        let successCode = 1;
        Authapi.forgetPasswordApi(username).then((response) => {
            if(response.data.status_code === successCode){
                setOpenAlert(true);
            }else{
                setErrorMessage(response.data.message);
            }
            
           
        }).catch((error) => { console.error(error) });
    }


    const { values, handleBlur, handleChange, handleSubmit, errors, touched } = useFormik({
        initialValues: {
            username: ""
        },
        onSubmit,
        validationSchema: Validation.EmailValidation
    });
    
    

    return (
        <>
            <Box className="text-wrap">
                <Box>
                    <h4>Forgot Password</h4>
                </Box>

                <Box className="text-description">
                    <p>Please enter your email to reset your password.</p>
                </Box>
            </Box>
            <Box className="form-style">
                <form onSubmit={handleSubmit}>
                    <Box className="form-content-wrap">
                        <Box>
                        <InputComponent
                                type="text"
                                placeholder="Email"
                                name="username"
                                value={values.username}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.username && errors.username}
                            />
                        </Box>
                        {errorMessage !== "" ? <Box><p style={{color:"red"}}>{errorMessage}</p></Box> : null}
                        <Box className="btn-group-wrap">
                            <ButtonComponent  buttontext="Back" buttoncolor="#616D89"  onClick={backButtonPress} buttonwidth={120} />
                            <ButtonComponent type="submit" onClick={handleSubmit} buttontext="Send" buttoncolor="#657ee4"  buttonwidth={120} />
                        </Box>
                    </Box>

                </form>
            </Box>

            <AlertComponent description="A password reset link has been sent to your email. Please confirm your email to reset password." buttontext="OK"  type="confirm-email" open={openAlert} onClose={()=>setOpenAlert(false)}/>
        </>
    )
}
export default ForgotPasswordComponent;