
const convertDate = (string) => {
const inputDate = string;
const date = new Date(inputDate);

const year = date.getFullYear();
const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
const day = (date.getDate()).toString().padStart(2, '0');

return`${day}/${month}/${year}`;
}

const stringDateToDate = (string) => {
    const dateString = string;
    const date = new Date(dateString);
    
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    
    return `${day}/${month}/${year}`;
}

const  removeExcessZerosDate = (isoDateTime) => {
    const parts = isoDateTime.split('.');
    if (parts.length === 2) {
      const fractionalSeconds = parts[1];
      const trimmedFractionalSeconds = fractionalSeconds.replace(/0+$/, '');
      if (trimmedFractionalSeconds === '') {
        return parts[0]; // If no non-zero digits remain, return just the date-time part
      }
      return parts[0] + '.' + trimmedFractionalSeconds;
    }
    return isoDateTime;
  }

const CommonMethods = {
    convertDate,
    stringDateToDate,
    removeExcessZerosDate,
}
export default CommonMethods;