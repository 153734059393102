import { Outlet } from "react-router-dom";
import Sidebar from "../componets/sidebar";
import { Box } from "@mui/material";
import "./style/style.scss";
import FooterComponent from "../componets/layouts/footer";
import HeaderComponent from "../componets/layouts/header";
import { useEffect, useState } from "react";

const Layout = ({ adminType }) => {
    const [page, setPage] = useState(adminType == 1 ? "Dashboard" : "Employees");
    useEffect(() => { 
        console.log(window.location.pathname)
    }, [page, adminType]);
    return (
        <>
            <Box className="layout">
                {adminType == 1 ? <Sidebar type={"super"} page={setPage} /> : <Sidebar type={"coperate"} page={setPage} />}
                {/* <Sidebar/> */}
                <Box className="content-layout">
                    <HeaderComponent path={page} userRole={adminType} />
                    <Box pt={2.5} pb={2.5} pl={1} pr={1} sx={{height:"maxContent"}}>
                        <Outlet />
                    </Box>
                    <FooterComponent />
                </Box>

            </Box>
        </>
    )
}

export default Layout;