import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import SecurityIcon from '@mui/icons-material/Security';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import { Grid } from '@mui/material';
// // style
import './style/style.scss';

// image
import Images from '../../assets/images';




const drawerWidth = 240;

function Sidebar({ window, type, page}) {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const setPage = (value) =>{
    page(value);
    // console.log(value);
  }

  const superAdminPages = [
    { icon: <DashboardIcon/>, name: "Dashboard", link: "/dashboard" },
    { icon: <PermIdentityIcon/>, name: "Private Users", link: "/private-users" },
    { icon: <PersonIcon/>, name: "Corporate Clients", link: "/coperate-clients" },
    { icon: <LockIcon/>, name: "Change Password", link: "/change-password" },
  ];

  const coOperateAdminPages = [
    {  icon: <PeopleIcon/> , name: "Employees", link: "/employees" },
    {  icon: <BusinessIcon/>, name: "Company Information", link: "/company-information" },
    {  icon: <SecurityIcon/> , name: "Change Password", link: "/change-password" },
  ];

  const drawer = (
    <div>
      <Toolbar>
        <Box><img src={Images.logo} alt="sidebar-logo" className='sidebar-logo' /></Box>
      </Toolbar>
      <Box sx={{ width: "100%", maxWidth: 360, bgcolor: 'background.paper'}}>
        {/* <nav aria-label="secondary mailbox folders"> */}
          <List>
            {type == "super" ? (<>
              {superAdminPages.map((page, index) => (
                <Link to={page.link} key={index}>
                  <ListItem disablePadding>
                    <ListItemButton>
                    <Grid container spacing={0.2} alignItems={"center"} >
                        <Grid item sx={{color:"#fff"}}>{page.icon}</Grid>
                        <Grid item> <ListItemText sx={{ color: "white" }}  primary={page.name} onClick={() => {
                        setPage(page.name);
                      }} /></Grid>
                      </Grid>
                    </ListItemButton>
                  </ListItem>
                </Link>
              ))}
            </>) : (<>
              {coOperateAdminPages.map((page, index) => (
                <Link to={page.link} key={index}>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <Grid container spacing={0.2} alignItems={"center"} >
                        <Grid item sx={{color:"#fff"}}>{page.icon}</Grid>
                        <Grid item> <ListItemText sx={{ color: "white" }}  primary={page.name} onClick={() => {
                        setPage(page.name);
                      }} /></Grid>
                      </Grid>
                     
                    </ListItemButton>
                  </ListItem>
                </Link>
              ))}</>)}

          </List>
        {/* </nav> */}
      </Box>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box>
      <Box sx={{ pl: 1.2, pt: 0.5 }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
      </Box>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth , bgcolor: "#657ee4"}, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          className='drawer'
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none', },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          className='drawer'
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}


export default Sidebar;