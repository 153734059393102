import React, { useEffect, useState } from "react";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import IconButtonComponent from "../../form/iconButtonComponent";
import AlertComponent from "../../alert";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Authapi from "../../../services/login/login";
import TokenService from "../../../services/axios/token";

// styles
import "./styles/style.scss";
import { useNavigate } from "react-router-dom";



const HeaderComponent = ({ path, userRole }) => {
    const [clickLogout, setClickLogout] = useState(false);
    const responseSuccess = 1;
    const navigate = useNavigate();

    useEffect(() => {getPath() }, [userRole, path])

    const logout = async () => {
        Authapi.signOutApi().then((response) => {
            if (response.data.status_code === responseSuccess) {
                TokenService.removeToken();
                navigate("/");
            } else {
                // console.log("ress: ",response.data.message);
            }
        }).catch((error) => { console.error(error) });
    }


    const getPath = () => {
        const pathname = window.location.pathname;
        const trimmedPathname = pathname.replace(/^\/|\/$/g, '');
        const formattedString = trimmedPathname.replace(/-/g, ' ');
        const capitalizedString = formattedString.charAt(0).toUpperCase() + formattedString.slice(1);
        return capitalizedString
    }

    return (<AppBar position="static">
        <Container maxWidth="xl">

            <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href={userRole == 1 ? "/dashboard" : "/employees"}>
                        <p>Home</p>
                    </Link>
                    <p color="text.primary">{getPath()}</p>
                </Breadcrumbs>
                <IconButtonComponent type={"logout"} buttonClick={() => setClickLogout(true)} />
            </Toolbar>
        </Container>
        <AlertComponent type="info" title="Logout!" description="Are you sure, You want to Logout?" buttontext="Yes" buttontext2="No" handleButtonClick={() => { logout() }} open={clickLogout} onClose={() => { setClickLogout(false) }} />
    </AppBar>);
}
export default HeaderComponent;