import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './styles/style.scss';


const PhoneNumberComponent = (props) => {
  const { error, label } = props;


  return (
    <>
      <p sx={{ fontWeight: "bold" }}>
        {label}
      </p>
      <PhoneInput
        className={'form-control input-phone-number'}
        international
        countryCallingCodeEditable={false}
        {...props} />

      {error !== undefined ? <p className="error-text">{props.error}</p> : null}
    </>
  );
}


export default PhoneNumberComponent;