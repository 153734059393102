import fullLogo from './logos/full_white_bg.jpg';
import clientIcon from './Icons/clients.png';
import handshake from './Icons/handshake.png';
import logo from './logos/sidebar_logo.png';
import changePassword from './uiImages/changePW.jpg';
import confirmEamil from "./uiImages/confirm_email.png"



// dev image
import companyLogo from './developmentImages/abstract-geometric-logo-or-infinity-line-logo-for-your-company-free-vector.jpg';



const Images = {
    fullLogo,
    clientIcon,
    handshake,
    companyLogo,
    logo,
    changePassword,
    confirmEamil
   
}

export default Images;
