import React, { forwardRef, useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Box, Grid, Typography } from "@mui/material";
import InputComponent from "../../../../componets/form/inputComponent";
import IconButtonComponent from "../../../../componets/form/iconButtonComponent";
import TextIconButtonComponent from "../../../../componets/form/textIconButtonComponent";
import ButtonComponent from "../../../../componets/form/buttonComponent";
import 'react-phone-number-input/style.css';
import SuperAdmin from "../../../../services/superAdmin/superAdmin";
import DatePickerComponent from "../../../../componets/form/datePickerComponent";

// styles
import "./styles/style.scss";
import moment from "moment/moment";
import Validation from "../../../../utils/Validation";
import AlertComponent from "../../../../componets/alert";
import PhoneNumberComponent from "../../../../componets/form/phoneNumberComponent";
import { formatPhoneNumberIntl } from "react-phone-number-input";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CooperateClientForm = ({ open, onClose, userId, getClientList }) => {
    const [edit, setEdit] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);
    const [clientInfo, setClientInfo] = useState({});
    const [baseUrl, setBaseUrl] = useState("");
    const [errorMsg, setErrormessage] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [clientData, setClientData] = useState({
        companyName: "",
        redemptionCode: "",
        contactPerson: "",
        contactNumber: "",
        email: "",
        noOfUsers: "",
        address: "",
        from: "",
        to: ""
    });
    const [errors, setErrors] = useState({});
    const [touchedFields, setTouchedFields] = useState({});
    const responseSuccess = 1;

    useEffect(() => {
        getCoperateClientDetails(userId);
    }, [userId, getClientList]);


    const getCoperateClientDetails = async (userId) => {
        SuperAdmin.coperateClientApi(userId).then((response) => {
            if (response?.data?.status_code === responseSuccess) {
                setClientInfo(response?.data?.data?.details);
                setClientData({
                    companyName: response?.data?.data?.details?.company_name,
                    redemptionCode: response?.data?.data?.details?.redemption_code,
                    contactPerson: response?.data?.data?.details?.first_name,
                    contactNumber: response?.data?.data?.details?.mobile_number,
                    email: response?.data?.data?.details?.email,
                    noOfUsers: response?.data?.data?.details?.no_of_users,
                    address: response?.data?.data?.details?.address,
                    from: response?.data?.data?.details?.start_date !== null ? new Date(moment(response?.data?.data?.details?.start_date)) : "",
                    to: response?.data?.data?.details?.end_date !== null ? new Date(moment(response?.data?.data?.details?.end_date)) : "",
                });

                setBaseUrl(response?.data?.data?.base_url);
            } else {
                setErrormessage(response?.data?.message);
                setErrorAlert(true);
            }
        }).catch((error) => { console.error(error) });
    }



    const updateCoperateClient = async (userId, clientId, companyName, contactPerson, redemptionCode, email, contactNumber, address, noOfUsers, subscriptionStart, subscriptionEnd) => {
        SuperAdmin.updateCoperateCilentApi(userId, clientId, companyName, contactPerson, redemptionCode, email, contactNumber, address, noOfUsers, subscriptionStart, subscriptionEnd).then((response) => {
            if (response.data.status_code === responseSuccess) {
                getCoperateClientDetails(userId);
                getClientList();
                setSuccessMsg(response?.data?.message);
                setSuccessAlert(true);
                setEdit(false);
            } else {
                setErrormessage(response?.data?.message);
                setErrorAlert(true);
            }
        }).catch((error) => { console.error(error) });
    }


    const editForm = () => {
        setEdit(true);
    }


    const handleFieldBlur = (fieldName) => {
        setTouchedFields((prevTouchedFields) => ({
            ...prevTouchedFields,
            [fieldName]: true,
        }));
    };


    const handleChange = async (field, value) => {
        setClientData((clientData) => ({
            ...clientData,
            [field]: value
        }));

        setErrors((clientData) => ({
            ...clientData,
            [field]: '',
        }));

        try {
            await Validation.CoperateClient(clientInfo?.filled_emp_count).validate(clientData, { abortEarly: false });
            // setCheckErrors(false);

        } catch (validationErrors) {
            const errorsObj = {};
            validationErrors.inner.forEach((error) => {
                errorsObj[error.path] = error.message;
            });
            setErrors(errorsObj);
        }


    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await Validation.CoperateClient(clientInfo?.filled_emp_count).validate(clientData, { abortEarly: false });
            updateCoperateClient(
                clientInfo?.user_id,
                clientInfo?.id,
                clientData.companyName,
                clientData.contactPerson,
                clientData.redemptionCode,
                clientData.email,
                clientData.contactNumber,
                clientData.address,
                clientData.noOfUsers,
                clientData?.from,
                clientData?.to);

        } catch (validationErrors) {
            const errorsObj = {};
            validationErrors.inner.forEach((error) => {
                errorsObj[error.path] = error.message;
            });
            setErrors(errorsObj);
        }
    };





    return (
        <Dialog
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
            maxWidth={"md"}
        >
            <DialogActions>
                <IconButtonComponent type="close" buttonClick={onClose} />
            </DialogActions>
            <DialogTitle>Coperate Client {edit ? "Edit" : "View"}</DialogTitle>

            <DialogContent >
                {edit ? null : (<>
                    <DialogActions>
                        <TextIconButtonComponent type="close" buttonClick={onClose} icon="edit" buttontext={"edit"} buttonwidth={100} handleClick={editForm} />
                    </DialogActions>
                    <Box className="img-section" mt={3} mb={3}>
                        <Box className="img-wrap">
                            <img src={baseUrl + clientInfo?.logo} alt="company-logo" />
                        </Box>
                    </Box>
                </>)}

                <Box >
                    <form onSubmit={handleSubmit} >
                        <Grid container spacing={1}>
                            <Grid item>
                                <Box className="form-content">
                                    <Box className="form-item">
                                        <InputComponent
                                            label="Company Name"
                                            placeholder="Company Name"
                                            name="companyName"
                                            value={clientData.companyName}
                                            onChange={(e) => handleChange('companyName', e.target.value)}
                                            onBlur={() => handleFieldBlur('companyName')}
                                            error={touchedFields.companyName && errors.companyName}
                                            readOnly={!edit}
                                        />
                                    </Box>
                                    <Box className="form-item">
                                        <PhoneNumberComponent
                                            placeholder="Contact Number"
                                            defaultCountry="GB"
                                            name="contactNumber"
                                            value={clientData.contactNumber}
                                            onChange={(e) => handleChange('contactNumber', formatPhoneNumberIntl(e))}
                                            onBlur={() => handleFieldBlur('contactNumber')}
                                            error={touchedFields.contactNumber && errors.contactNumber}
                                        />
                                    </Box>

                                    <Box className="form-item">
                                        <InputComponent
                                            label="Email"
                                            placeholder="Email"
                                            name="email"
                                            value={clientData.email}
                                            onChange={(e) => handleChange('email', e.target.value)}
                                            onBlur={() => handleFieldBlur('email')}
                                            error={touchedFields.email && errors.email}
                                            readOnly={!edit}
                                        />
                                    </Box>

                                    <Box className="form-item">
                                        <InputComponent
                                            label="Address"
                                            placeholder="Address"
                                            name="address"
                                            value={clientData.address}
                                            onChange={(e) => handleChange('address', e.target.value)}
                                            onBlur={() => handleFieldBlur('address')}
                                            error={touchedFields.address && errors.address}
                                            readOnly={!edit}
                                        />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box className="form-content">
                                    <Box className="form-item">
                                        <InputComponent
                                            label="Redemption Code"
                                            placeholder="Redemption Code"
                                            name="redemptionCode"
                                            value={clientData.redemptionCode}
                                            onChange={(e) => handleChange('redemptionCode', e.target.value)}
                                            onBlur={() => handleFieldBlur('redemptionCode')}
                                            readOnly={!edit}
                                        />
                                    </Box>
                                    <Box className="form-item">
                                        <InputComponent
                                            label="Contact Person"
                                            placeholder="Contact Person"
                                            name="contactPerson"
                                            value={clientData.contactPerson}
                                            onChange={(e) => handleChange('contactPerson', e.target.value)}
                                            onBlur={() => handleFieldBlur('contactPerson')}
                                            error={touchedFields.contactPerson && errors.contactPerson}
                                            readOnly={!edit}
                                        />
                                    </Box>

                                    <Box className="form-item">
                                        <Typography variant="body1" sx={{ fontWeight: "bold" }}></Typography>
                                        <Box>
                                            <p>No of users</p>
                                            <Box className="no-of-user">
                                                <Box className="form-control" sx={{width:"fit-content", backgroundColor:"#F7F7F8"}}>
                                                    <p>{clientInfo?.filled_emp_count?.toString().padStart(2, '0')}</p>
                                                </Box>
                                                <p style={{fontSize:"30px", color:"#75787d"}}>/</p>
                                                <Box>
                                                    <InputComponent

                                                        placeholder="No of Users"
                                                        name="noOfUsers"
                                                        value={clientData.noOfUsers}
                                                        onChange={(e) => handleChange('noOfUsers', e?.target?.value)}
                                                        onBlur={() => handleFieldBlur('noOfUsers')}
                                                        error={touchedFields.noOfUsers && errors.noOfUsers}
                                                        readOnly={!edit}
                                                    />
                                                </Box>

                                            </Box>
                                        </Box>

                                    </Box>



                                    <Box className="form-item">
                                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>Current Subscription Period</Typography>
                                        <Box className="current-subscription-wrap">
                                            <Box>

                                                <DatePickerComponent
                                                    id="from"
                                                    name="from"
                                                    label={"From Date"}
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={clientData?.from}
                                                    onBlur={() => handleFieldBlur('from')}
                                                    selectsStart
                                                    startDate={clientData?.from}
                                                    endDate={clientData?.to}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    onChange={(e) => handleChange('from', e)}
                                                    readOnly={!edit} />
                                            </Box>

                                            <Box>
                                                <DatePickerComponent
                                                    id="to"
                                                    name="to"
                                                    label={"To Date"}
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={clientData?.to}
                                                    onBlur={() => handleFieldBlur('to')}
                                                    selectsEnd
                                                    startDate={clientData?.from}
                                                    endDate={clientData?.to}
                                                    minDate={clientData?.from}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    onChange={(e) => handleChange('to', e)}
                                                    readOnly={!edit}
                                                />

                                            </Box>
                                        </Box>

                                    </Box>
                                    {edit ? <Box className="button-group">
                                        <Box className="button-wrap">
                                            <ButtonComponent type="submit" onClick={handleSubmit} buttontext="Save" buttoncolor="#657ee4" buttonwidth={100} />
                                        </Box>
                                        <Box className="button-wrap">
                                            <ButtonComponent buttontext="Cancel" buttoncolor="#616D89" buttonwidth={100} onClick={() => { setEdit(false) }} />
                                        </Box>
                                    </Box> : null}

                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </Box>

            </DialogContent>
            <AlertComponent title="Success" description={successMsg} buttontext="OK" type="success" open={successAlert} onClose={() => { setSuccessAlert(false); onClose() }} />
            <AlertComponent title="Error" description={errorMsg} buttontext="OK" type="error" open={errorAlert} onClose={() => { setErrorAlert(false) }} />

        </Dialog>
    );
}
export default CooperateClientForm;