import * as React from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide } from '@mui/material'
import IconButtonComponent from '../../../../componets/form/iconButtonComponent'
import InputComponent from "../../../../componets/form/inputComponent";
import ButtonComponent from "../../../../componets/form/buttonComponent";
import SuperAdmin from "../../../../services/superAdmin/superAdmin";
import Validation from "../../../../utils/Validation";
import AlertComponent from "../../../../componets/alert";
import PhoneNumberComponent from "../../../../componets/form/phoneNumberComponent";
import { formatPhoneNumberIntl } from "react-phone-number-input";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddClient = ({ open = false, onClose = () => { }, getAllclients = () => { } }) => {
    const [viewAlert, setViewAlert] = React.useState(false);
    const [errorAlert, setErrorAlert] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const success = 1;
    const [clientData, setClientData] = React.useState({
        companyName: "",
        redemptionCode: "",
        contactPerson: "",
        contactNumber: "",
        email: "",
        noOfUsers: "",
        address: ""
    });
    const [errors, setErrors] = React.useState({});
    const [touchedFields, setTouchedFields] = React.useState({});

    React.useEffect(() => {
    }, [getAllclients, errors]);


    const createCoperateClient = async (companyName, contactPerson, redemptionCode, email, contactNumber, address, noOfUsers) => {
        SuperAdmin.createCoperateClientApi(companyName, contactPerson, redemptionCode, email, contactNumber, address, noOfUsers).then((response) => {
            if (response?.data?.status_code === success) {
                setMessage(response?.data?.message);
                setViewAlert(true);
                getAllclients();
                setClientData({
                    companyName: "",
                    redemptionCode: "",
                    contactPerson: "",
                    contactNumber: "",
                    email: "",
                    noOfUsers: "",
                    address: ""
                });

            } else {
                setMessage(response?.data?.message);
                setErrorAlert(true);
            }
        }).catch((error) => { console.error(error) });
    }


    const handleFieldBlur = async (fieldName) => {
        setTouchedFields((prevTouchedFields) => ({
            ...prevTouchedFields,
            [fieldName]: true,
        }));

        try {
            await Validation.CoperateClient(0).validate(clientData, { abortEarly: false });

        } catch (validationErrors) {
            const errorsObj = {};
            validationErrors.inner.forEach((error) => {
                errorsObj[error.path] = error.message;
            });
            setErrors(errorsObj);
        }
    };


    const handleChange = async (field, value) => {
        setClientData((clientData) => ({
            ...clientData,
            [field]: value
        }));

        setErrors((clientData) => ({
            ...clientData,
            [field]: '',
        }));

        try {
            await Validation.CoperateClient(0).validate(clientData, { abortEarly: false });
        } catch (validationErrors) {
            const errorsObj = {};
            validationErrors.inner.forEach((error) => {
                errorsObj[error.path] = error.message;
            });
            console.log(errorsObj);
            setErrors(errorsObj);
        }


    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await Validation.CoperateClient(0).validate(clientData, { abortEarly: false });
            createCoperateClient(clientData?.companyName, clientData?.contactPerson, clientData?.redemptionCode, clientData?.email, clientData?.contactNumber, clientData?.address, clientData?.noOfUsers);
        } catch (validationErrors) {
            const errorsObj = {};
            validationErrors.inner.forEach((error) => {
                errorsObj[error.path] = error.message;
            });

            if(errorsObj?.address || errorsObj?.companyName || errorsObj?.contactNumber || errorsObj?.contactPerson || errorsObj?.email || errorsObj?.noOfUsers || errorsObj?.redemptionCode) {
                setMessage("Please insert client information before submitting!");
                setErrorAlert(true);
            }

            console.log(errorsObj);
            setErrors(errorsObj);
        }
    };



    return (
        <Dialog
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
            maxWidth={"md"}

        >
            <DialogActions>
                <IconButtonComponent type="close" buttonClick={onClose} />
            </DialogActions>
            <DialogTitle>Add New Coperate Client</DialogTitle>

            <DialogContent>
                <Box >
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={1}>
                            <Grid item>
                                <Box className="form-content">
                                    <Box className="form-item">
                                        <InputComponent
                                            type="text"
                                            label="Company Name"
                                            placeholder="Company Name"
                                            name="companyName"
                                            value={clientData.companyName}
                                            onChange={(e) => handleChange('companyName', e.target.value)}
                                            onBlur={() => handleFieldBlur('companyName')}
                                            error={touchedFields.companyName && errors.companyName}
                                        />
                                    </Box>
                                    <Box className="form-item">
                                        <InputComponent
                                            type="text"
                                            label="Contact Person"
                                            name="contactPerson"
                                            placeholder="Contact Person"
                                            value={clientData.contactPerson}
                                            onChange={(e) => handleChange('contactPerson', e.target.value)}
                                            onBlur={() => handleFieldBlur('contactPerson')}
                                            error={touchedFields.contactPerson && errors.contactPerson}
                                        />
                                    </Box>

                                    <Box className="form-item">
                                        <InputComponent
                                            type="text"
                                            label="Email"
                                            name="email"
                                            placeholder="Email"
                                            value={clientData.email}
                                            onChange={(e) => handleChange('email', e.target.value)}
                                            onBlur={() => handleFieldBlur('email')}
                                            error={touchedFields.email && errors.email}
                                        />
                                    </Box>


                                </Box>

                            </Grid>
                            <Grid item>
                                <Box className="form-item">
                                    <InputComponent
                                        type="text"
                                        label="Address"
                                        name="address"
                                        placeholder="Address"
                                        value={clientData.address}
                                        onChange={(e) => handleChange('address', e.target.value)}
                                        onBlur={() => handleFieldBlur('address')}
                                        error={touchedFields.address && errors.address}
                                    />
                                </Box>
                                <Box className="form-content">
                                    <Box className="form-item">
                                        <InputComponent
                                            label="Redemption Code"
                                            type="text"
                                            name="redemptionCode"
                                            placeholder="Redemption Code"
                                            value={clientData.redemptionCode}
                                            onChange={(e) => handleChange('redemptionCode', e.target.value)}
                                            onBlur={() => handleFieldBlur('redemptionCode')}
                                            error={touchedFields.redemptionCode && errors.redemptionCode}
                                        />
                                    </Box>
                                    <Box className="form-item">
                                        <PhoneNumberComponent
                                            placeholder="Contact Number"
                                            defaultCountry="GB"
                                            name="contactNumber"
                                            value={clientData.contactNumber}
                                            onChange={(e) => handleChange('contactNumber', formatPhoneNumberIntl(e))}
                                            onBlur={() => handleFieldBlur('contactNumber')}
                                            error={touchedFields.contactNumber && errors.contactNumber}
                                        />
                                    </Box>
                                    <Box className="form-item">

                                        <InputComponent
                                            label="No of users"
                                            type="text"
                                            name="noOfUsers"
                                            placeholder="No of users"
                                            value={clientData.noOfUsers}
                                            onChange={(e) => handleChange('noOfUsers', e?.target?.value)}
                                            onBlur={() => handleFieldBlur('noOfUsers')}
                                            error={touchedFields.noOfUsers && errors.noOfUsers}
                                        />
                                    </Box>
                                </Box>

                                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                                    <Box p={0.5}> <ButtonComponent type="submit" onClick={handleSubmit} buttontext="Add" buttonwidth={100} buttoncolor={"#657ee4"} /></Box>
                                    <Box p={0.5}><ButtonComponent buttontext="cancel" onClick={() => {
                                        onClose(); setClientData({
                                            companyName: "",
                                            redemptionCode: "",
                                            contactPerson: "",
                                            contactNumber: "",
                                            email: "",
                                            noOfUsers: "",
                                            address: ""
                                        });
                                        setErrors({});
                                    }} buttonwidth={100} buttoncolor={"#657ee4"} /></Box>
                                </Box>
                            </Grid>

                        </Grid>
                    </form>
                </Box>

            </DialogContent>
            <AlertComponent title={"Success"} description={message} buttontext={"OK"} type={"success"} open={viewAlert} onClose={() => { setViewAlert(false); onClose(); }} />
            <AlertComponent title={"Error"} description={message} buttontext={"OK"} type={"error"} open={errorAlert} onClose={() => { setErrorAlert(false) }} />

        </Dialog>
    )
}

export default AddClient;