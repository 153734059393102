import baseApi from "../axios/axios";
import { paths } from "../paths";



const getAllEmployees = async (userId) => {
    return await baseApi.instance.post(paths.getAllEmployees, {user_id: userId});
}

const getEmployee = async (userId) => {
    return await baseApi.instance.post(paths.getEmployee, { user_id: userId });
}

const createCoperateEmployee = async (firstName, lastName, email, phoneNo, address, gender, dob, country_code) => {
    return await baseApi.instance.post(paths.createEmployee, { first_name: firstName, last_name: lastName, email: email, phone_no: phoneNo, address: address, gender: gender, dob: dob, country_code:country_code });
}

const editEmployee = async (userId, firstName, lastName, email, phoneNo, address, gender, dob) => {
    return await baseApi.instance.post(paths.editEmployee,{ user_id: userId , first_name: firstName, last_name: lastName, email: email, phone_no: phoneNo, address: address, gender: gender, dob: dob});
}

const uploadBulkEmployees = async (object) => {
    return await baseApi.mediaHeadersInstance.post(paths.importBulkEmployee, object);
}

const changeEmployeeStatus = async (userId, status) => {
    return await baseApi.instance.post(paths.changeEmployeeStatus, { user_id: userId ,status: status});
}

const deleteEmployee = async (userId) => {
    return await baseApi.instance.post(paths.deleteEmployee, { user_id: userId});
}

const getCompanyInfo = async (userId) => {
    return await baseApi.instance.post(paths.getCompanyInfo, {user_id: userId});
}

const updateCompanyInfo = async (object) => {
    return await baseApi.mediaHeadersInstance.post(paths.editCompanyInfo, object);
}
const CoperateUser = {
    getAllEmployees,
    createCoperateEmployee,
    editEmployee,
    uploadBulkEmployees,
    getEmployee,
    changeEmployeeStatus,
    deleteEmployee,
    getCompanyInfo,
    updateCompanyInfo
};

export default CoperateUser;